import styled from "styled-components";
import media from "./media";

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  ${media.desktop`
    padding: 0 1rem;
  `}
`;

/* 삭제 예정 */

export const Segment = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#FFF")};
  overflow: hidden;
`;
