import React from "react";
import styled, { keyframes } from "styled-components";
import { Container } from "../styles/common";
import CheckOrder from "./checkOrder";
import theme from "../styles/theme";
import media from "../styles/media";
import { darken } from "polished";
import { Waypoint } from "react-waypoint";

const ticketAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const Segment = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#FFF")};
  overflow: hidden;

  padding-top: 80px;

  background-image: url("/image/start/bg.png");
  background-repeat: no-repeat;
  background-position: center 200px;
  text-align: center;
  h2 {
    font-weight: 500;
  }
  img {
    margin-top: 30px;
    vertical-align: bottom;
    opacity: 0;
    &.add-anim {
      animation: ${ticketAnim} 0.5s ease-in forwards;
      animation-delay: 0.25s;
    }
    ${media.phone`
      width: 60%;
    `}
  }

  ${media.phone`
    background-position: center 220px;
    background-size: 84%;
  `}
`;

const D = styled.span`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: -1px;
    left: 11px;
    width: 4px;
    height: 4px;
    background-color: #12628a;
    border-radius: 100%;
  }
`;

const Btn = styled.button`
  background-color: ${(props) => props.bgColor};
  font-size: 2.6rem;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  padding: 1.8rem 4rem;
  border-radius: 4rem;
  transition: all 0.5s;
  &:hover {
    background-color: ${(props) => darken(0.1, props.bgColor)};
  }
`;

const addAnim = () => {
  const divs = document.querySelectorAll(`.start__anim-area`);
  for (let i = 0; i < divs.length; i++) {
    divs[i].classList.add("add-anim");
  }
};

function Start({ handleContact }) {
  return (
    <Segment bgColor={theme.colors.blue2}>
      <Waypoint onEnter={addAnim}>
        <Container>
          <h2 className="desktop">
            퍼핀플레이스와 함께{" "}
            <span style={{ fontWeight: 500, color: "#12628a" }}>
              <D>우</D>
              <D>리</D>
              <D>집</D> <D>공</D>
              <D>기</D>
              <D>질</D> <D>관</D>
              <D>리</D>
            </span>
            를 시작해보세요.
          </h2>
          <h2 className="phone">
            퍼핀플레이스와 함께 <br />
            <span style={{ fontWeight: 500, color: "#12628a" }}>우리집 공기질 관리</span>를 시작해보세요.
          </h2>
          <Btn bgColor={theme.colors.mainBlue} onClick={() => handleContact(true)}>
            퍼핀플레이스 체험하기
          </Btn>

          <CheckOrder />
          <img src="/image/start/ticket.png" alt="ticket" className="start__anim-area ticket-area" />
        </Container>
      </Waypoint>
    </Segment>
  );
}

export default Start;
