import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { darken } from "polished";

const MobileNavBtn = styled.div`
  /* background-color: red; */
  position: fixed;
  right: 20px;
  top: 16px;
  height: 30px;
  z-index: 30;
  transition: all 0.5s;

  span {
    display: block;
    width: 30px;
    height: 3px;
    background-color: #e4e4e6;
    margin-bottom: 10px;
  }

  &:hover span {
    background-color: ${(props) => darken(0.1, "#e4e4e6")};
  }
`;

const MobileNav = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 60%;
  padding: 10px 20px;
  background-color: #116389;
  opacity: 0.9;
  z-index: 999;
  /* display: (${(props) => (props.isShowMobileMenu ? "block" : "hidden")}); */

  .close-btn {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .links {
    margin: 80px 0 0 0;
    padding: 0;
    list-style: none;
    li {
      text-align: center;
    }
  }
  a {
    text-align: center;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 30px;
    font-size: 3.2rem;
    z-index: 3;
    transition: all 0.5s;

    &.active,
    &:hover {
      font-weight: 500;
      border-bottom: 2px solid #fff;
    }
  }
`;

function MobileMenu() {
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);

  useEffect(() => {
    if (isShowMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isShowMobileMenu]);
  return (
    <>
      <MobileNavBtn className="tabletAndPhone" onClick={() => setIsShowMobileMenu(!isShowMobileMenu)}>
        <span />
        <span />
        <span />
      </MobileNavBtn>

      {isShowMobileMenu && (
        <MobileNav isShowMobileMenu={isShowMobileMenu}>
          <div className="close-btn" onClick={() => setIsShowMobileMenu(!isShowMobileMenu)}>
            <img src="/image/header/close_btn_w.png" alt="close" />
          </div>
          <ul className="links">
            <li>
              <NavLink to={`/`} exact>
                홈
              </NavLink>
            </li>
            <li>
              <NavLink to={`/membership`}>멤버십</NavLink>
            </li>
            <li>
              <NavLink to={`/guide`}>이용안내</NavLink>
            </li>
            <li>
              <NavLink to={`/support`}>고객센터</NavLink>
            </li>
          </ul>
        </MobileNav>
      )}
    </>
  );
}

export default MobileMenu;
