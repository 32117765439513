import React from "react";
import styled, { keyframes } from "styled-components";
import { Waypoint } from "react-waypoint";
import theme from "../styles/theme";
import media from "../styles/media";

const bubbleAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Segment = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 1044px;
  background-image: url("/image/service/service_overview.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding-top: 60px;

  ${media.tablet`
    padding-top: 0px;
    min-height: auto;
  `}

  @media (min-width: 1900px) {
    min-height: 1400px;
  }

  h2,
  h3 {
    text-align: center;
  }

  h3 {
    font-size: 4rem;
    font-weight: 500;
  }

  .background-mobile {
    display: none;

    ${media.tablet`
      display:block;
      width: 100%;
    `}
  }

  .title {
    ${media.tablet`
      position: absolute;
      left: 50%;
      top: 60px;
      transform: translateX(-50%);
      width: 340px;
    `}

    ${media.phone`
      top: 30px;
    `}
  }

  .bubble {
    text-align: center;
    display: inline-block;
    position: absolute;
    opacity: 0;
    h4 {
      font-size: 3.8rem;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
      font-size: 2.4rem;
      font-weight: 500;
      margin-top: 0;
      color: #676767;
    }

    span {
      color: #1baae6;
    }

    ${media.tablet`
      text-align:left;
      h4 {
        font-size: 2.6rem;
      }
      p {
        font-size: 2.1rem;
      }
    `}
  }

  .bubble1.add-anim {
    opacity: 0;
    animation: ${bubbleAnim} 0.5s ease-in forwards;
    animation-delay: 0.25s;
  }

  .bubble2.add-anim {
    opacity: 0;
    animation: ${bubbleAnim} 0.5s ease-in forwards;
    animation-delay: 0.25s;
  }

  .bubble1 {
    /* 모바일 기준 */
    width: 214px;
    height: 92px;

    left: 32%;
    bottom: 0;

    background-image: url("/image/service/bubble1_m.png");
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;

    padding-top: 10px;
    padding-left: 20px;

    @media (min-width: 768px) {
      width: 260px;
      height: 110px;
      padding-top: 0;
    }

    @media (min-width: 992px) {
      width: 507px;
      height: 147px;

      left: 50%;
      top: 50%;
      bottom: revert;

      transform: translate(-64%, 200%);

      background-image: url("/image/service/bubble1.png");

      padding-top: 30px;
    }

    @media (min-width: 1900px) {
      transform: translate(-80%, 264%);
    }
  }

  .bubble2 {
    /* 모바일 기준 */
    top: 30%;
    right: 20%;
    width: 254px;
    height: 88px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 60px;
    background-position: 0 0;
    background-image: url("/image/service/bubble2_m.png");
    padding-top: 10px;
    padding-left: 10px;

    @media (min-width: 768px) {
      width: 332px;
      height: 116px;
    }

    @media (min-width: 992px) {
      width: 497px;
      height: 197px;

      right: 10px;
      padding-top: 60px;

      background-image: url("/image/service/bubble2.png");
    }

    @media (min-width: 1510px) {
      left: 50%;
      top: 50%;
      right: revert;

      transform: translate(50%, -120%);
    }

    @media (min-width: 1900px) {
      transform: translate(80%, -156%);
    }
  }
`;

const D = styled.span`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: -1px;
    left: 11px;
    width: 4px;
    height: 4px;
    background-color: #1baae6;
    border-radius: 100%;
    ${media.tablet`
    left: 9px;
    width: 3px;
    height: 3px;
    `}
  }
`;

function serviceOverView() {
  const addAnim = (index) => {
    const divs = document.querySelectorAll(`.overview__anim-area-${index}`);
    for (let i = 0; i < divs.length; i++) {
      divs[i].classList.add("add-anim");
    }
  };

  return (
    <Segment>
      <div className="title">
        <h2>서비스 소개</h2>
        <h3>
          천장에{" "}
          <span style={{ color: theme.colors.mainBlue }}>
            <D>환</D>
            <D>기</D>
            <D>구</D>
          </span>
          가 보이시나요?
        </h3>
      </div>
      <div className="bubble bubble1 overview__anim-area-1">
        <Waypoint onEnter={() => addAnim(1)} />
        <h4>프리미엄 공기질 센서 설치</h4>
        <p>온도, 습도, 이산화탄소, 화학물질, 미세먼지를 측정합니다.</p>
      </div>

      <div className="bubble bubble2 overview__anim-area-2">
        <Waypoint onEnter={() => addAnim(2)} />
        <h4>환기구에 인공지능 컨트롤러 부착</h4>
        <p>
          하루 평균 <span>500회 이상</span> 환기 여부를 판단하고,
          <br />
          우리 집 공기를 알아서 정화합니다.
        </p>
      </div>
      <img className="background-mobile" src="/image/service/service_overview_m.jpg" alt="service1" />
    </Segment>
  );
}

export default serviceOverView;
