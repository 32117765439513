import React from "react";
import styled from "styled-components";
import { Container } from "../styles/common";
import media from "../styles/media";
import { Link, NavLink } from "react-router-dom";

const Segment = styled.div`
  background-color: white;
  height: 60px;
  position: fixed;
  width: 100%;
  z-index: 2;
  ${media.tablet`
    position: relative;
    z-index:0;
  `}
`;

const Wrapper = styled.div`
  height: 60px;
`;

const Logo = styled.img`
  margin-top: 14px;
  margin-bottom: 10px;
  margin-left: 14px;
  width: 240px;
  ${media.phone`
    margin-top: 20px;
    margin-left: 0px;
    width: 160px;
  `}
`;

const Nav = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const NavItem = styled(NavLink)`
  font-weight: 400;
  font-size: 2rem;
  text-decoration: none;
  color: #222;
  margin-left: 30px;
  transition: all 0.5s;
  padding-bottom: 4px;

  &.active,
  &:hover {
    font-weight: 500;
    color: #116389;
    border-bottom: 2px solid #116389;
  }
`;

function Header() {
  return (
    <Wrapper>
      <Segment>
        <Container style={{ position: "relative" }}>
          <Link to={"/"}>
            <Logo src="/image/header/logo.svg" alt="PUFFIN PLACE" />
          </Link>
          <Nav>
            <div className="onlyDesktop">
              <NavItem to={`/membership`}>멤버십</NavItem>
              <NavItem to={`/guide`}>이용안내</NavItem>
              <NavItem to={`/support`}>고객센터</NavItem>
            </div>
          </Nav>
        </Container>
      </Segment>
    </Wrapper>
  );
}

export default Header;
