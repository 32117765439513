import { css } from "styled-components";
import theme from "./theme";

const sizes = {
  desktop: theme.breakpoints.desktop.max,
  tablet: theme.breakpoints.tablet.max,
  phone: theme.breakpoints.phone.max,
  smallPhone: theme.breakpoints.smallPhone.max,
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export default media;
