import React from "react";
import { useLocation, Link } from "react-router-dom";

function Header({ title }) {
  const param = useParams();

  return (
    <div className="fixed top-0 py-4 text-center shadow bg-main-blue w-full">
      {param.get("home-btn") ? (
        <Link to={"/apartment"}>
          <h1 className="text-white text-2xl font-bold">{title}</h1>
        </Link>
      ) : (
        <h1 className="text-white text-2xl font-bold">{title}</h1>
      )}
    </div>
  );
}

const useParams = () => {
  return new URLSearchParams(useLocation().search);
};

export default Header;
