import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import Modal from "styled-react-modal";
import Header from "../components/header";
import MobileMenu from "../components/mobileMenu";
import Membership from "../components/membership";
import Footer from "../components/footer";

import { ModalProvider } from "styled-react-modal";

import Contact from "../components/contact";
import media from "../styles/media";

import GlobalStyle from "../styles/globalStyle";
import theme from "../styles/theme";
import "../styles/react-select-search.css";

const StyledModal = Modal.styled`
  width: 600px;
  height: 500px;
  ${media.phone`
  height: 530px;          
  `}
  background-color: #FFF;
  border-radius: 4%;
  position:relative;   
  margin-left: 1rem;
  margin-right: 1rem;
`;

function MembershipPage() {
  const [isOpen, setIsOpen] = useState(false);
  const handleContact = (bool) => setIsOpen(bool);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <GlobalStyle />
        <Header />
        <MobileMenu />
        <Membership handleContact={handleContact} />
        <Footer />
        <StyledModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <Contact setIsOpen={setIsOpen} />
        </StyledModal>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default MembershipPage;
