import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Header from "../components/header";
import Footer from "../components/footer";

import { Container } from "../styles/common";
import FilterStep0 from "../components/filter/step0";
import FilterStep1 from "../components/filter/step1";
import FilterStep2 from "../components/filter/step2";
// import FilterStepPayMethod from "../components/filter/stepPayMethod";
import FilterStepSuccess from "../components/filter/stepSuccess";
import FilterStepFailed from "../components/filter/stepFailed";

import { useParams } from "react-router-dom";

import GlobalStyle from "../styles/globalStyle";
import theme from "../styles/theme";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { INIT_FILTER_PAGE, GET_SCHEDULE_PROPOSAL, GET_SCHEDULE_LIST } from "../graphql/query";
import { USER_INQUIRY, CREATE_VISIT_REQUEST_FOR_FLOOR, RESERVE_VISIT_REQUEST, CREATE_ORDER, ORDER_PAID } from "../graphql/mutation";
import media from "../styles/media";

function Filter() {
  const { orgId, locationId } = useParams();
  const [aptName, setAptName] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [filterPrice, setFilterPrice] = useState("");
  const [filterChangePrice, setFilterChangePrice] = useState("");

  // const [filterProduct, setFilterProduct] = useState();
  // const [filterChangeProduct, setFilterChangeProduct] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dong, setDong] = useState("");
  const [ho, setHo] = useState("");

  const [inviteeStartTime, setInviteeStartTime] = useState(); // 선택된 시작날짜
  const [floorId, setFloorId] = useState();
  const [kaptCode, setKaptCode] = useState("");
  const [currentStep, setCurrentStep] = useState("0"); // "0" | "1" | "2" |"PAY_METHOD" |  "SUCCESS" | "FAILED"

  const [errorMsg, setErrorMsg] = useState("");

  const myTrim = (x) => {
    return x.replace(/^\s+|\s+$/gm, "");
  };

  // 초기화
  useQuery(INIT_FILTER_PAGE, {
    variables: { organizationId: orgId },
    onCompleted(data) {
      const location = data.organization?.locations?.find((cur) => cur._id === locationId);
      if (!location?.kaptInfo?.kaptName || !location?.kaptInfo?.kaptCode) {
        return setCurrentStep("FAILED");
      }
      setAptName(location.kaptInfo?.kaptName);
      setKaptCode(location.kaptInfo?.kaptCode);
      setStoreUrl(location.storeUrl);
      setFilterPrice(location.filterPrice);
      setFilterChangePrice(location.filterChangePrice);
    },
    onError(error) {
      setCurrentStep("FAILED");
      console.error(error);
    },
  });

  //1) user 생성
  const [userInquiry, { data: userInquiryData }] = useMutation(USER_INQUIRY, {
    onCompleted(data) {
      if (!data.userInquiry.floors.length) {
        return;
      }
      setFloorId(data.userInquiry.floors[0]._id);
      getScheduleProposal({ variables: { locationId: locationId, floorId: data.userInquiry.floors[0]._id } });
    },
    onError(error) {
      console.error(error.message);
    },
  });

  const submitUserData = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    if (myTrim(name) === "" || myTrim(phone) === "" || myTrim(dong) === "" || myTrim(ho) === "") {
      // email은 필수 아님
      setErrorMsg("입력되지 않은 값이 있습니다.");
      return;
    }

    const phoneRole = /^\d{3}-\d{3,4}-\d{4}$/;
    if (!phoneRole.test(phone)) {
      setErrorMsg("휴대폰 번호가 유효하지 않습니다.");
      return false;
    }

    if (email && !isEmail(email)) {
      setErrorMsg("유효한 이메일 형식이 아닙니다.");
      return;
    }

    setIsLoading(true);
    await userInquiry({
      variables: {
        data: {
          name: myTrim(name),
          phone: myTrim(phone),
          email: myTrim(email),
        },
        kaptCode: kaptCode,
        floorName: `${myTrim(dong)}동 ${myTrim(ho)}호`,
      },
    });
  };

  // 2-1) 추천방문일자 조회
  const [getScheduleProposal, { data: proposalData }] = useLazyQuery(GET_SCHEDULE_PROPOSAL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      getScheduleList({ variables: { locationId: locationId } });
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  // 2-2) 전체방문가능일자 조회
  const [getScheduleList, { data: listData }] = useLazyQuery(GET_SCHEDULE_LIST, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setCurrentStep("2");
      setIsLoading(false);
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  //3-1) visitRequest 생성
  const [createVisitRequestForFloor] = useMutation(CREATE_VISIT_REQUEST_FOR_FLOOR, {
    onCompleted(data) {
      const visitRequestId = data.createVisitRequestForFloor._id;
      reserveVisitRequest({
        variables: {
          visitRequestId: visitRequestId,
        },
      });
    },
    onError(error) {
      setIsLoading(false);
      console.error(error.message);
    },
  });

  //3-2) 예약
  const [reserveVisitRequest] = useMutation(RESERVE_VISIT_REQUEST, {
    onCompleted(data) {
      setIsLoading(false);
      setCurrentStep("SUCCESS");
    },
    onError(error) {
      setIsLoading(false);
      console.error(error.message);
    },
  });

  const submitInviteeData = (timeData) => {
    setIsLoading(true);
    createVisitRequestForFloor({
      variables: {
        floorId: floorId,
        data: {
          type: "filter",
          inviteeStartTime: timeData.inviteeStartTime,
          inviteeEndTime: timeData.inviteeEndTime,
        },
      },
    });
  };

  const getStepTitle = () => {
    if (currentStep === "0") return "환기시스템 필터 구매";
    return "환기시스템 필터 방문 교체 신청";
  };

  const isEmail = (email) => {
    const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regExp.test(email);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <FilterPage>
        {isLoading && (
          <Loading>
            <img src="/image/loading.gif" alt="loading" />
          </Loading>
        )}
        <Container>
          <h2>
            <span>{aptName}</span>
            <br />
            {getStepTitle()}
          </h2>
          {currentStep === "0" && (
            <FilterStep0 setCurrentStep={setCurrentStep} storeUrl={storeUrl} filterPrice={filterPrice} filterChangePrice={filterChangePrice} />
          )}
          {currentStep === "1" && (
            <FilterStep1
              name={name}
              phone={phone}
              email={email}
              dong={dong}
              ho={ho}
              setName={setName}
              setPhone={setPhone}
              setEmail={setEmail}
              setDong={setDong}
              setHo={setHo}
              submitUserData={submitUserData}
              errorMsg={errorMsg}
            />
          )}
          {currentStep === "2" && (
            <FilterStep2
              submitInviteeData={submitInviteeData}
              inviteeStartTime={inviteeStartTime}
              setInviteeStartTime={setInviteeStartTime}
              scheduleProposal={proposalData.getScheduleProposal}
              scheduleList={listData.getScheduleList}
            />
          )}
          {currentStep === "SUCCESS" && <FilterStepSuccess name={name} phone={phone} email={email} dong={dong} ho={ho} inviteeStartTime={inviteeStartTime} />}
          {currentStep === "FAILED" && <FilterStepFailed />}
        </Container>
      </FilterPage>
      <Footer />
    </ThemeProvider>
  );
}

export default Filter;

const FilterPage = styled.div`
  background-color: #f7f5ee;
  position: relative;
  padding-top: 40px;
  padding-bottom: 80px;
  ${media.phone`

  `}
  h2 {
    color: #404040;
    font-size: 3.6rem;
    text-align: center;
    margin-bottom: 6rem;
    line-height: 1.8em;
    span {
      font-size: 2.8rem;
    }
  }
`;

const Loading = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  img {
    opacity: 0.6;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
