import React, { useState, useReducer } from "react";
import Header from "../../components/apartment/filter/header";
import FilterStep0 from "../../components/apartment/filter/step0";
import FilterStep1 from "../../components/apartment/filter/step1";
import FilterStep2 from "../../components/apartment/filter/step2";
import FilterStepSuccess from "../../components/apartment/filter/stepSuccess";
import ApartmentLayout from "../../layout/apartmentLayout";

import { useParams } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { INIT_FILTER_PAGE, GET_SCHEDULE_PROPOSAL, GET_SCHEDULE_LIST } from "../../graphql/query";
import { USER_INQUIRY, CREATE_VISIT_REQUEST_FOR_FLOOR, RESERVE_VISIT_REQUEST } from "../../graphql/mutation";

const initialState = {
  name: "",
  email: "",
  phone: "",
  dong: "",
  ho: "",
};

function Filter() {
  const { orgId, locationId } = useParams();
  const [aptName, setAptName] = useState("");
  const [storeUrl, setStoreUrl] = useState("");
  const [filterPrice, setFilterPrice] = useState("");
  const [filterChangePrice, setFilterChangePrice] = useState("");

  // const [filterProduct, setFilterProduct] = useState();
  // const [filterChangeProduct, setFilterChangeProduct] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [formState, dispatch] = useReducer(formReducer, initialState);
  const { name, email, phone, dong, ho } = formState;

  const [inviteeStartTime, setInviteeStartTime] = useState(); // 선택된 시작날짜
  const [floorId, setFloorId] = useState();
  const [kaptCode, setKaptCode] = useState("");

  const [currentStep, setCurrentStep] = useState("0"); // "0" | "1" | "2" | "SUCCESS" | "FAILED"

  const [errorMsg, setErrorMsg] = useState("");

  const onChange = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  // 초기화
  useQuery(INIT_FILTER_PAGE, {
    variables: { organizationId: orgId },
    onCompleted(data) {
      const location = data.organization?.locations?.find((cur) => cur._id === locationId);
      if (!location?.kaptInfo?.kaptName || !location?.kaptInfo?.kaptCode) {
        return setCurrentStep("FAILED");
      }
      setAptName(location.kaptInfo?.kaptName);
      setKaptCode(location.kaptInfo?.kaptCode);
      setStoreUrl(location.storeUrl);
      setFilterPrice(location.filterPrice);
      setFilterChangePrice(location.filterChangePrice);
    },
    onError(error) {
      setCurrentStep("FAILED");
      console.error(error);
    },
  });

  //1) user 생성
  const [userInquiry] = useMutation(USER_INQUIRY, {
    onCompleted(data) {
      if (!data.userInquiry.floors.length) {
        return;
      }

      setFloorId(data.userInquiry.floors[0]._id);
      getScheduleProposal({ variables: { locationId: locationId, floorId: data.userInquiry.floors[0]._id } });
    },
    onError(error) {
      console.error(error.message);
    },
  });

  const submitUserData = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    if (name === "" || phone === "" || dong === "" || ho === "") {
      // email은 필수 아님
      setErrorMsg("입력되지 않은 값이 있습니다.");
      return;
    }

    const phoneRole = /^\d{3}-\d{3,4}-\d{4}$/;
    if (!phoneRole.test(phone)) {
      setErrorMsg("휴대폰 번호가 유효하지 않습니다.");
      return false;
    }

    if (email && !isEmail(email)) {
      setErrorMsg("유효한 이메일 형식이 아닙니다.");
      return;
    }

    setIsLoading(true);

    await userInquiry({
      variables: {
        data: {
          name,
          phone,
          email,
        },
        kaptCode: kaptCode,
        floorName: `${dong}동 ${ho}호`,
      },
    });
  };

  // 2-1) 추천방문일자 조회
  const [getScheduleProposal, { data: proposalData }] = useLazyQuery(GET_SCHEDULE_PROPOSAL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      getScheduleList({ variables: { locationId: locationId } });
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  // 2-2) 전체방문가능일자 조회
  const [getScheduleList, { data: listData }] = useLazyQuery(GET_SCHEDULE_LIST, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setCurrentStep("2");
      setIsLoading(false);
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  //3-1) visitRequest 생성
  const [createVisitRequestForFloor] = useMutation(CREATE_VISIT_REQUEST_FOR_FLOOR, {
    onCompleted(data) {
      const visitRequestId = data.createVisitRequestForFloor._id;
      reserveVisitRequest({
        variables: {
          visitRequestId: visitRequestId,
        },
      });
    },
    onError(error) {
      setIsLoading(false);
      console.error(error.message);
    },
  });

  //3-2) 예약
  const [reserveVisitRequest] = useMutation(RESERVE_VISIT_REQUEST, {
    onCompleted(data) {
      setIsLoading(false);
      setCurrentStep("SUCCESS");
    },
    onError(error) {
      setIsLoading(false);
      console.error(error.message);
    },
  });

  const submitInviteeData = (timeData) => {
    setIsLoading(true);
    createVisitRequestForFloor({
      variables: {
        floorId: floorId,
        data: {
          type: "filter",
          inviteeStartTime: timeData.inviteeStartTime,
          inviteeEndTime: timeData.inviteeEndTime,
        },
      },
    });
  };

  const isEmail = (email) => {
    const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regExp.test(email);
  };

  return (
    <ApartmentLayout>
      <div className={isLoading ? "overflow-hidden h-screen" : ""}>
        <div className={(!isLoading && "hidden") + " loading-wrapper"}>
          <img className="w-6" src="/image/apartment/common/loading.gif" alt="loading" />
        </div>
        <Header title={aptName} />
        <div className="page-body">
          {currentStep === "0" && (
            <FilterStep0 setCurrentStep={setCurrentStep} storeUrl={storeUrl} filterPrice={filterPrice} filterChangePrice={filterChangePrice} />
          )}
          {currentStep === "1" && (
            <FilterStep1 name={name} phone={phone} email={email} dong={dong} ho={ho} onChange={onChange} submitUserData={submitUserData} errorMsg={errorMsg} />
          )}
          {currentStep === "2" && (
            <FilterStep2
              submitInviteeData={submitInviteeData}
              inviteeStartTime={inviteeStartTime}
              setInviteeStartTime={setInviteeStartTime}
              scheduleProposal={proposalData?.getScheduleProposal || []}
              scheduleList={listData?.getScheduleList || []}
            />
          )}
          {currentStep === "SUCCESS" && <FilterStepSuccess name={name} phone={phone} email={email} dong={dong} ho={ho} inviteeStartTime={inviteeStartTime} />}
          {currentStep === "FAILED" && <h2 className="page-title text-3xl text-center">유효하지 않은 접근입니다.</h2>}
        </div>
      </div>
    </ApartmentLayout>
  );
}

export default Filter;

const formReducer = (state, { field, value }) => {
  let currentValue = myTrim(value);

  if (field === "phone") {
    const strOriginal = value.replace(/[^0-9]/g, "");
    currentValue = strOriginal.replace(/(^01.{1})([0-9]+)([0-9]{4})/, "$1-$2-$3");
  }

  return {
    ...state,
    [field]: currentValue,
  };
};

const myTrim = (x) => {
  return x.replace(/^\s+|\s+$/gm, "");
};
