import React, { useState } from "react";
import PpModal from "../../../components/apartment/filter/ppModal";

function Step0({ setCurrentStep, storeUrl, filterPrice, filterChangePrice }) {
  const [isOpenPpModal, setIsOpenPpModal] = useState(false);
  return (
    <>
      <h2 className="page-title">원하는 서비스를 선택해 주세요.</h2>
      <div className="flex mt-10 flex-col md:flex-row">
        {storeUrl && (
          <a className="menu-btn" href={storeUrl} target="_blank" rel="noopener noreferrer">
            <div>
              <h3>필터 배송</h3>
              {!!filterPrice && <p className="price">{filterPrice.toLocaleString()}원</p>}
            </div>
            <img className="menut-btn__icon" src="/image/apartment/common/arrow.png" alt="arrow" />
          </a>
        )}

        <div
          className="menu-btn"
          onClick={() => {
            setCurrentStep("1");
          }}
        >
          <div>
            <h3>필터 방문 교체</h3>
            {!!filterChangePrice && <p className="price">{filterChangePrice.toLocaleString()}원</p>}
          </div>
          <img className="menut-btn__icon" src="/image/apartment/common/arrow.png" alt="arrow" />
        </div>

        {/* <a className="menu-btn" href="https://pf.kakao.com/_Tygcxb" target="_blank" rel="noopener noreferrer"> */}
        <div className="menu-btn" onClick={() => setIsOpenPpModal(true)}>
          <div>
            <h3>정기 관리 서비스</h3>
            {!!filterPrice && <p className="price">&nbsp;</p>}
          </div>
          <img className="menut-btn__icon" src="/image/apartment/common/arrow.png" alt="arrow" />
        </div>
        {/* </a> */}
      </div>
      <PpModal isOpenPpModal={isOpenPpModal} setIsOpenPpModal={setIsOpenPpModal} />
    </>
  );
}

export default Step0;
