import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import Header from "../components/header";
import MobileMenu from "../components/mobileMenu";
import Footer from "../components/footer";
import { Container } from "../styles/common";

import { ModalProvider } from "styled-react-modal";

import { Grid, GridItem } from "styled-grid-responsive";
import media from "../styles/media";

import GlobalStyle from "../styles/globalStyle";
import theme from "../styles/theme";

const Segment = styled.div`
  background-color: #f7f5ee;

  h2 {
    text-align: center;
    margin-bottom: 80px;
  }
  h3 {
    font-size: 3.1rem;
    margin: 0;
  }
  p {
    font-size: 2rem;
    margin: 0;
  }
  h4 {
    text-align: center;
    font-size: 2.6rem;
    color: #394750;
  }
`;

const Line = styled.div`
  position: absolute;
  top: 0;
  left: 80px;
  bottom: 238px;
  width: 1px;
  border-left: 2px solid #d3d3d7;
  ${media.phone`
    left: 20px;
  `}
`;

const Content = styled.div`
  margin-left: 180px;
  ${media.phone`
    margin-left: 30px;
  `}
`;

const Step = styled.div`
  margin-bottom: 60px;
  position: relative;

  ${media.phone`
    margin-bottom: 80px;
  `}

  .step-label {
    font-weight: 700;
    position: absolute;
    top: 0;
    left: -166px;
    color: ${(props) => props.color};
    font-size: 1.5rem;
    ${media.phone`
      left: 0px;
      top: -20px;
    `}
  }
  .step-dot {
    position: absolute;
    content: "";
    top: 7px;
    left: -103px;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.color};
    border-radius: 100%;
    @media (max-width: 1230px) {
      left: -111px;
    }
    ${media.phone`
      left: -19px;
      top: -16px;
    `}

    &:after {
      position: absolute;
      content: "";
      top: -2px;
      left: -2px;
      width: 12px;
      height: 12px;
      background-color: ${(props) => props.color};
      opacity: 0.5;
      border-radius: 100%;
    }
  }
  .puffin-label {
    position: absolute;
    top: -28px;
    left: -94px;
    background-color: ${(props) => props.color};
    color: ${(props) => (props.color === "#f5b866" ? "#4c4c4e" : "#ffffff")};
    display: inline-block;
    padding: 2px 8px;
    font-size: 1.6rem;
    font-weight: 700;
    border-radius: 4px;
    &:before {
      position: absolute;
      content: "";
      top: 23px;
      left: 8px;

      width: 0px;
      height: 0px;
      border-bottom: 10px solid transparent;
      border-left: 10px solid ${(props) => props.color};
      ${media.phone`
        top: 20px;
        left: 6px;
      `}
    }
    ${media.phone`
      left: -6px;
      top: -50px;
    `}
  }
`;

const StyledGrid = styled(Grid)`
  max-width: 400px;
  line-height: 1.4;
  position: relative;
  /* margin-bottom: 60px; */
  .item {
    text-align: center;
    background-color: #fff;
    padding: 30px 0;
    border-radius: 10px;
    box-shadow: 2px 2px 0px 1px #fbe588;
    ${media.phone`
      font-size: 1.9rem;
    `}
  }
  .arrow {
    position: absolute;
    top: 50%;
    width: 10px;
    height: 10px;
    border-right: 2px solid #fbe588;
    border-top: 2px solid #fbe588;
    transform: translateY(-50%) rotate(45deg);
    margin-left: 6px;
    ${media.phone`
    margin-left: 2px;
    `}
  }
`;

function Guide() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <GlobalStyle />
        <Header />
        <MobileMenu />
        <Segment>
          <Container style={{ position: "relative", paddingTop: "80px", paddingBottom: "80px" }}>
            <Line />
            <h2>퍼핀플레이스 이용 안내</h2>

            <Content>
              <Step color="#f5b866">
                <div className="step-label">STEP 1</div>
                <div className="step-dot" />
                <div className="puffin-label">퍼핀스타터</div>
                <h3>체험하기 신청</h3>
                <p>퍼핀플레이스 홈페이지에서 신청하세요.</p>
              </Step>
              <Step color="#f5b866">
                <div className="step-label">STEP 2</div>
                <div className="step-dot" />
                <h3>방문 희망일 정하기</h3>
                <p>신청해주신 날짜로 오퍼레이터가 방문해 공기질 센서와 인공지능 컨트롤러를 설치합니다.</p>
              </Step>
              <Step color="#f5b866">
                <div className="step-label">STEP 3</div>
                <div className="step-dot" />
                <h3>14일간 체험</h3>
                <StyledGrid full>
                  <GridItem col={5 / 17}>
                    <div className="item">
                      공기질
                      <br />
                      학습
                    </div>
                  </GridItem>
                  <GridItem col={1 / 17}>
                    <div className="arrow" />
                  </GridItem>
                  <GridItem col={5 / 17}>
                    <div className="item">
                      인공지능
                      <br />
                      자율환기
                    </div>
                  </GridItem>
                  <GridItem col={1 / 17}>
                    <div className="arrow" />
                  </GridItem>
                  <GridItem col={5 / 17}>
                    <div className="item">
                      공기질리포트
                      <br />
                      발송
                    </div>
                  </GridItem>
                </StyledGrid>
              </Step>
              <Step color="#1a618a">
                <div className="step-label">STEP 4</div>
                <div className="step-dot" />
                <div className="puffin-label">퍼핀네스트</div>
                <h3>멤버십 가입</h3>
                <p>
                  우리집 공기가 얼마나 변화되었는지 비교 & 분석 이후
                  <br />
                  멤버십 가입 여부를 결정지으세요!
                </p>
              </Step>
              <Step color="#1a618a">
                <div className="step-label">STEP 5</div>
                <div className="step-dot" />
                <h3>서비스 이용</h3>
                <p>
                  멤버십 기간 동안
                  <br />
                  인공지능 자율 환기, 공기질 모니터링 서비스, 필터 배송 서비스가 진행됩니다.
                </p>
              </Step>
            </Content>
            <h4 className="desktop">
              &ldquo;세상에서 가장 청정한 지역, 아이슬란드에 사는 퍼핀이
              <br />
              당신의 공간에도 날아들 수 있도록&rdquo;
            </h4>
            <h4 className="phone">
              &ldquo;세상에서 가장 청정한 지역,
              <br />
              아이슬란드에 사는 퍼핀이
              <br />
              당신의 공간에도 날아들 수 있도록&rdquo;
            </h4>
          </Container>
        </Segment>
        <Footer />
      </ModalProvider>
    </ThemeProvider>
  );
}

export default Guide;
