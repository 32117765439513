import React from "react";
import styled, { keyframes } from "styled-components";
import theme from "../styles/theme";
import media from "../styles/media";
import { Waypoint } from "react-waypoint";

const phoneAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const textLeftAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const textRightAnim = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const decoLeftAnim = keyframes`
  0% {
      opacity: 0;
      transform: translateX(-20px);
  }
  100% {
      opacity: 1;
      transform: translateX(0px);
  }
`;

const decoRightAnim = keyframes`
  0% {
      opacity: 0;
      transform: translateX(20px);
  }
  100% {
      opacity: 1;
      transform: translateX(0px);
  }
`;

const Segment = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#FFF")};
  overflow: hidden;

  position: relative;
  height: 734px;

  .phone-img {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 1;
  }
  p {
    font-size: 3.2rem;
    font-weight: 700;
  }
  span {
    color: #1baae6;
  }

  .phone-area {
    opacity: 0;
    &.add-anim {
      animation: ${phoneAnim} 0.5s ease-in forwards;
      animation-delay: 0.25s;
    }
  }
  .text-left-area {
    opacity: 0;
    &.add-anim {
      animation: ${textLeftAnim} 0.5s ease-in forwards;
      animation-delay: 0.5s;
    }
  }
  .text-right-area {
    opacity: 0;
    &.add-anim {
      animation: ${textRightAnim} 0.5s ease-in forwards;
      animation-delay: 0.5s;
    }
  }
  .deco-left-area {
    opacity: 0;
    &.add-anim {
      animation: ${decoLeftAnim} 1s ease-in forwards;
      animation-delay: 1s;
    }
  }
  .deco-right-area {
    opacity: 0;
    &.add-anim {
      animation: ${decoRightAnim} 1s ease-in forwards;
      animation-delay: 1s;
    }
  }

  ${media.phone`
     height: 476px;

    .phone-img img {
        width: 160px;
    }
  `}

  ${media.smallPhone`
     height: 400px;

    .phone-img img {
        width: 120px;
    }
  `}
`;

const Text = styled.div`
  width: 400px;
  height: 200px;

  position: absolute;

  &.left {
    left: 50%;
    top: 50%;

    transform: translate(${({ index }) => (index === 1 && "-153%, -65%") || (index === 2 && "-153%, -30%") || (index === 3 && "-153%, -70%")});

    p {
      text-align: right;
      &:before {
        position: absolute;
        content: "";
        background-image: url("/image/service/line_left.png");
        background-repeat: no-repeat;
        top: 16px;
        right: -80px;
        width: 58px;
        height: 10px;
      }
    }
  }
  &.right {
    left: 50%;
    top: 50%;

    transform: translate(${({ index }) => (index === 1 && "53%, -30%") || (index === 2 && "53%, -30%") || (index === 3 && "53%, -50%")});

    p:before {
      position: absolute;
      content: "";
      background-image: url("/image/service/line_right.png");
      background-repeat: no-repeat;
      top: 40px;
      left: -80px;
      width: 58px;
      height: 10px;
    }
  }

  ${media.tablet`
   p{
      font-size:2rem;
    }  
  `};

  ${media.phone`

    position: absolute;
    width: 100px;
      

    &.left {
     
      transform: translate(${({ index }) => (index === 1 && "-186%, -38%") || (index === 2 && "-186%, -17%") || (index === 3 && "-186%, -22%")});


      p:before {
        background-image: url("/image/service/line_left_m.png");
        top: -14px;
        right: -42px;
        width: 58px;
        height: 10px;
      }
    }
    &.right {
      
      transform: translate(${({ index }) => (index === 1 && "89%, -4%") || (index === 2 && "89%, -17%") || (index === 3 && "89%, -12%")});

      p:before {
        background-image: url("/image/service/line_right_m.png");
        top: -15px;
        left: -44px;
        width: 58px;
        height: 10px;
      }
    }
    p{
      font-size:2.3rem;
    }
  `}

  ${media.smallPhone`

    &.left {
      transform: translate(${({ index }) => (index === 1 && "-169%, -38%") || (index === 2 && "-169%, -17%") || (index === 3 && "-169%, -22%")});
    }
    &.right {
      transform: translate(${({ index }) => (index === 1 && "66%, -4%") || (index === 2 && "66%, -17%") || (index === 3 && "66%, -12%")});
    }
    p{
      font-size:2rem;
    }
  `}
`;

const Deco = styled.div`
  width: 480px;
  position: absolute;
  left: 50%;
  bottom: 0;
  &.deco1 {
    transform: translateX(-140%);
    text-align: right;
  }
  &.deco2 {
    transform: translateX(${({ index }) => (index === 1 && "40%") || (index === 2 && "30%") || (index === 3 && "-9%")});
  }
  img {
    vertical-align: bottom;
  }

  ${media.tablet`
    transform-origin:bottom center;
    &.deco1 {
      transform: scale(0.8) translateX(${({ index }) => (index === 1 && "-162%") || (index === 2 && "-160%") || (index === 3 && "-165%")});
    }
    &.deco2 {
      transform: scale(0.8) translateX(${({ index }) => (index === 1 && "35%") || (index === 2 && "17%") || (index === 3 && "-16%")});
    }    
  `}

  ${media.phone`
    transform-origin:bottom center;
    &.deco1 {
      transform: scale(0.5) translateX(${({ index }) => (index === 1 && "-186%") || (index === 2 && "-186%") || (index === 3 && "-190%")});
    }
    &.deco2 {
      transform: scale(0.5) translateX(${({ index }) => (index === 1 && "-18%") || (index === 2 && "-38%") || (index === 3 && "-70%")});
    }    
  `}

  ${media.smallPhone`
    transform-origin:bottom center;
    &.deco1 {
      transform: scale(0.5) translateX(${({ index }) => (index === 1 && "-178%") || (index === 2 && "-178%") || (index === 3 && "-190%")});
    }
    &.deco2 {
      transform: scale(0.5) translateX(${({ index }) => (index === 1 && "-35%") || (index === 2 && "-45%") || (index === 3 && "-78%")});
    }    
  `}
`;

const addAnim = (index) => {
  const divs = document.querySelectorAll(`.service__anim-area-${index}`);
  for (let i = 0; i < divs.length; i++) {
    divs[i].classList.add("add-anim");
  }
};

// const removeAnim = (index) => {
//   const divs = document.querySelectorAll(`.service__anim-area-${index}`);
//   for (let i = 0; i < divs.length; i++) {
//     divs[i].classList.remove("add-anim");
//   }
// };

function Service({ index }) {
  const bgColors = { 1: theme.colors.yellow, 2: theme.colors.white, 3: theme.colors.blue1 };
  return (
    // <Waypoint onEnter={() => addAnim(index)} onLeave={() => removeAnim(index)}>
    <Waypoint onEnter={() => addAnim(index)}>
      <Segment bgColor={bgColors[index]}>
        <div className="phone-img">
          <img className={`service__anim-area-${index} phone-area`} src={`/image/service/phone${index}.png`} alt="phone1" />
        </div>
        {index === 1 && (
          <>
            <Text className="left" index={index}>
              <div className={`service__anim-area-${index} text-left-area`}>
                <p>
                  공기 상태에 따라
                  <br />
                  <span>알아서 환기</span>
                </p>
              </div>
            </Text>
            <Text className="right" index={index}>
              <div className={`service__anim-area-${index} text-right-area`}>
                <p className="desktop">
                  우리집 공기 오염요소
                  <br />
                  <span>실시간 모니터링</span>
                </p>
                <p className="phone">
                  우리집 공기
                  <br />
                  오염요소
                  <br />
                  <span>실시간 모니터링</span>
                </p>
              </div>
            </Text>
          </>
        )}
        {index === 2 && (
          <>
            <Text className="left" index={index}>
              <div className={`service__anim-area-${index} text-left-area`}>
                <p className="onlyDesktop">
                  8월 4일 오후 4시
                  <br />
                  <span>이산화탄소가 급증했네요!</span>
                </p>
                <p className="tabletAndPhone">
                  8월 4일 오후 4시
                  <br />
                  <span>
                    이산화탄소가
                    <br />
                    급증했네요!
                  </span>
                </p>
              </div>
            </Text>
            <Text className="right" index={index}>
              <div className={`service__anim-area-${index} text-right-area`}>
                <p className="onlyDesktop">
                  Fan Speed를 3배로 증가시켜
                  <br />
                  <span>
                    이산화탄소가 급감하는 모습,
                    <br />
                    보이시나요?
                  </span>
                </p>
                <p className="tabletAndPhone">
                  Fan Speed를
                  <br />
                  3배로 증가시켜
                  <br />
                  <span>
                    이산화탄소가
                    <br />
                    급감하는 모습,
                    <br />
                    보이시나요?
                  </span>
                </p>
              </div>
            </Text>
          </>
        )}
        {index === 3 && (
          <>
            <Text className="left" index={index}>
              <div className={`service__anim-area-${index} text-left-area`}>
                <p>
                  생활패턴에 맞춰
                  <br />
                  <span>환기 모드 설정</span>
                </p>
              </div>
            </Text>
            <Text className="right" index={index}>
              <div className={`service__anim-area-${index} text-right-area`}>
                <p>
                  수면중에도
                  <br />
                  <span>조용하게!</span>
                </p>
              </div>
            </Text>
          </>
        )}
        <Deco className="deco1" index={index}>
          <div className={`service__anim-area-${index} deco-left-area`}>
            <img src={`/image/service/service${index}_1.png`} alt="service2_1" />
          </div>
        </Deco>
        <Deco className="deco2" index={index}>
          <div className={`service__anim-area-${index} deco-right-area`}>
            <img src={`/image/service/service${index}_2.png`} alt="service2_2" />
          </div>
        </Deco>
      </Segment>
    </Waypoint>
  );
}

export default Service;
