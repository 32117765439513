import React from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ko";
import media from "../../styles/media";

function FilterStepSuccess({ name, phone, email, dong, ho, inviteeStartTime }) {
  return (
    <SubmitedBox>
      <h3>신청이 완료되었습니다.</h3>
      {inviteeStartTime && <h4>{moment(inviteeStartTime).locale("ko").format("YYYY년 M월 DD일 a h시 m분")}에 방문 예정입니다.</h4>}
      <div>이름: {name}</div>
      <div>휴대폰: {phone}</div>
      <div>이메일: {email}</div>
      <div>
        상세주소(동호수): {dong}동 {ho}호
      </div>
    </SubmitedBox>
  );
}

export default FilterStepSuccess;

const SubmitedBox = styled.div`
  width: 600px;
  margin: 0 auto;
  min-height: 400px;

  ${media.tablet`
    width: 80%;
  `}
  h3 {
    font-size: 2.6rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  h4 {
    margin-top: 1rem;
    font-size: 2.3rem;
    text-align: center;
  }
  div {
    font-size: 1.9rem;
    max-width: 400px;
    margin: 0 auto;
    font-weight: 400;
  }
`;
