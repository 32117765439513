import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "./pages/main";
import Membership from "./pages/membership";
import Guide from "./pages/guide";
import Support from "./pages/support";
import Filter from "./pages/filter";
import Reschedule from "./pages/reschedule";
import PaymentCompleteMobile from "./pages/paymentCompleteMobile";

import ApartmentFilter from "./pages/apartment/filter";
import ApartmentReschedule from "./pages/apartment/reschedule";
import ApartmentNew from "./pages/apartment/new";
import ApartmentMain from "./pages/apartment/main";

import { positions, Provider } from "react-alert";
import AlertMUITemplate from "react-alert-template-mui";

const alertOptions = {
  position: positions.MIDDLE,
};

function Routes() {
  return (
    <BrowserRouter>
      <Provider template={AlertMUITemplate} {...alertOptions}>
        <Switch>
          <Route path="/payment/complete/mobile" component={PaymentCompleteMobile} />
          <Route path="/mode/:mode" component={Main} />
          <Route path="/membership" component={Membership} />
          <Route path="/guide" component={Guide} />
          <Route path="/support" component={Support} />
          <Route path="/filter/org/:orgId/location/:locationId" component={Filter} />
          <Route path="/reschedule/request/:requestId" component={Reschedule} />

          <Route path="/apartment/filter/org/:orgId/location/:locationId" component={ApartmentFilter} />
          <Route path="/apartment/reschedule/request/:requestId/opt/:opt" component={ApartmentReschedule} />
          <Route path="/apartment/reschedule/request/:requestId" component={ApartmentReschedule} />
          <Route path="/apartment/new" component={ApartmentNew} />
          <Route path="/apartment/" component={ApartmentMain} />

          <Route path="/" component={Main} />
        </Switch>
      </Provider>
    </BrowserRouter>
  );
}

export default Routes;
