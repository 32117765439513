import React, { useState } from "react";
import Header from "../../components/apartment/header";

import FilterStep2 from "../../components/apartment/filter/step2";
import moment from "moment";

import { useParams } from "react-router-dom";

import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { GET_VISIT_REQUEST, GET_SCHEDULE_PROPOSAL, GET_SCHEDULE_LIST } from "../../graphql/query";
import { UPDATE_VISIT_REQUEST, RESERVE_VISIT_REQUEST } from "../../graphql/mutation";
import ApartmentLayout from "../../layout/apartmentLayout";

function Reschedule() {
  const { requestId, opt } = useParams(); // 신규 생성의 경우 opt === 'new'
  const [isLoading, setIsLoading] = useState(true);
  const [currentInviteeStartTime, setCurrentInviteeStartTime] = useState(); // 현재의 시작날짜
  const [inviteeStartTime, setInviteeStartTime] = useState(); // 선택된 시작날짜
  const [currentStep, setCurrentStep] = useState("2"); //  "2" | "SUCCESS" | "FAILED"
  const [locationId, setLocationId] = useState();

  // 1-1) visitRequest 조회
  const { data: visitRequestData } = useQuery(GET_VISIT_REQUEST, {
    variables: { visitRequestId: requestId },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.visitRequest?.inviteeStartTime) {
        setCurrentInviteeStartTime(moment(data.visitRequest?.inviteeStartTime).format("YYYY-MM-DD, a h:mm"));
        setInviteeStartTime(new Date(data.visitRequest?.inviteeStartTime));
      }
      const locationId = data.visitRequest?.floor?.location?._id;
      if (!locationId) {
        setIsLoading(false);
        return setCurrentStep("FAILED");
      }
      setLocationId(locationId);
      getScheduleProposal({ variables: { locationId: locationId, floorId: data.visitRequest?.floor?._id } });
    },
    onError(error) {
      setIsLoading(false);
      setCurrentStep("FAILED");
      console.error(error);
    },
  });

  // 1-2) 추천방문일자 조회
  const [getScheduleProposal, { data: proposalData }] = useLazyQuery(GET_SCHEDULE_PROPOSAL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      getScheduleList({ variables: { locationId: locationId } });
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  // 1-3) 전체방문가능일자 조회
  const [getScheduleList, { data: listData }] = useLazyQuery(GET_SCHEDULE_LIST, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setIsLoading(false);
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  //2-1) visitRequest 변경
  const [updateVisitRequest] = useMutation(UPDATE_VISIT_REQUEST, {
    onCompleted(data) {
      const visitRequestId = data.updateVisitRequest._id;
      reserveVisitRequest({
        variables: {
          visitRequestId: visitRequestId,
        },
      });
    },
    onError(error) {
      setIsLoading(false);
      console.error(error.message);
    },
  });

  //2-2) 예약
  const [reserveVisitRequest] = useMutation(RESERVE_VISIT_REQUEST, {
    onCompleted(data) {
      setCurrentInviteeStartTime(moment(data.reserveVisitRequest.inviteeStartTime).format("YYYY-MM-DD, a h:mm"));
      setIsLoading(false);
      setCurrentStep("SUCCESS");
    },
    onError(error) {
      setIsLoading(false);
      console.error(error.message);
    },
  });

  const submitInviteeData = (timeData) => {
    setIsLoading(true);
    updateVisitRequest({
      variables: {
        visitRequestId: requestId,
        data: {
          inviteeStartTime: timeData.inviteeStartTime,
          inviteeEndTime: timeData.inviteeEndTime,
        },
      },
    });
  };

  return (
    <ApartmentLayout>
      <div className={isLoading ? "overflow-hidden h-screen" : ""}>
        <div className={(!isLoading && "hidden") + " loading-wrapper"}>
          <img className="w-6" src="/image/apartment/common/loading.gif" alt="loading" />
        </div>
        <Header />
        <div className="page-body">
          <h2 className="reschedule-title">
            {visitRequestData?.visitRequest?.floor?.location?.kaptInfo?.kaptName} {visitRequestData?.visitRequest?.floor?.name}
          </h2>
          {currentStep === "2" && proposalData && listData && (
            <>
              {currentInviteeStartTime && <h3 className="reschedule-subtitle">방문 예정 일시 : {currentInviteeStartTime}</h3>}
              <FilterStep2
                submitInviteeData={submitInviteeData}
                inviteeStartTime={inviteeStartTime}
                setInviteeStartTime={setInviteeStartTime}
                scheduleProposal={proposalData.getScheduleProposal}
                scheduleList={listData.getScheduleList}
                isModify={true}
              />
            </>
          )}
          {currentStep === "SUCCESS" && (
            <>
              {opt !== "new" && <h2 className="reschedule-title"> 방문 일정이 변경되었습니다</h2>}
              <h3 className="reschedule-subtitle">방문 예정 일시 : {currentInviteeStartTime}</h3>
            </>
          )}
          {currentStep === "FAILED" && (
            <>
              <h2 className="reschedule-title">접근에 실패하였습니다</h2>
            </>
          )}
        </div>
      </div>
    </ApartmentLayout>
  );
}

export default Reschedule;
