import React from "react";
import styled from "styled-components";
import { Container } from "../styles/common";
import { Grid, GridItem } from "styled-grid-responsive";
import theme from "../styles/theme";
import media from "../styles/media";

const Segment = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#FFF")};
  overflow: hidden;
  padding-top: 80px;

  background-image: url("/image/membership/bg.png");
  background-repeat: no-repeat;
  background-position: center 58%;

  ${media.desktop`
    background-image: url("/image/membership/bg_m.png");
    background-size: 90%;
    background-position: center 72%;
    
  `}

  h2 {
    margin-top: 0;
    text-align: center;
    &.title {
      margin-bottom: 60px;
    }
  }

  h3 {
    text-align: center;
  }

  .ticket-image {
    display: block;
    margin: 0 auto;
    ${media.phone`
      width: 80%;
      margin: 0 auto;
    `}
  }

  .deco1 {
    position: absolute;
    bottom: 0;
    left: 10%;
  }

  .deco2 {
    position: absolute;
    bottom: 0;
    right: 10%;
  }
`;

const StyledGrid = styled(Grid)`
  position: relative;
  margin-bottom: 60px;

  .item {
    text-align: center;
    ${media.phone`
      min-height: 160px;
      img{
        width: 80%;
        margin-top:10px;
      }
    `}
  }
  .plus {
    margin-top: 40px;
  }
  p {
    line-height: 1.2em;
    margin-top: 0;
    font-weight: 700;
  }
  .plus-m {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: none;
    ${media.phone`
      display: block;
    `}
  }
  .line1-m {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 1px;
    background-color: #fff;
    display: none;
    ${media.phone`
      display: block;
    `}
  }
  .line2-m {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 80%;
    background-color: #fff;
    display: none;
    ${media.phone`
      display: block;
    `}
  }
`;

const Tickets = styled.div`
  margin-bottom: 60px;
`;

const Ticket = styled.div`
  position: relative;
  img {
    width: 100%;
  }
  div {
    position: absolute;
  }
  .tier {
    color: ${(props) => (props.color ? props.color : "#ccc")};
    left: 28px;
    top: 30px;
    font-size: 3.8rem;
    font-weight: 700;
    border-bottom: 3px solid ${(props) => (props.color ? props.color : "#ccc")};
  }
  .num {
    left: 28px;
    bottom: 30px;
    font-size: 2.6rem;
    font-weight: 700;
    span {
      color: ${(props) => (props.color ? props.color : "#ccc")};
    }
  }
  .price {
    right: 28px;
    bottom: 30px;
    font-size: 4rem;
    font-weight: 700;
  }

  ${media.desktop`
    
    .tier {
      left: 16px;
      top: 19px;
      font-size: 3.1rem;
    }

    .num {
      left: 16px;
      bottom: 19px;
      font-size: 2.3rem;
    }

    .price {
      right: 16px;
      bottom: 19px;
      font-size: 3.3rem;
    }
  
  `}

  ${media.phone`
    width: 80%;
    margin: 0 auto 20px auto;

    .tier {
      left: 16px;
      top: 19px;
      font-size: 3.4rem;
    }

    .num {
      left: 16px;
      bottom: 19px;
      font-size: 2.6rem;
    }

    .price {
      right: 16px;
      bottom: 19px;
      font-size: 3.6rem;
    }
    
  `}
`;

const Btn = styled.button`
  background-color: ${(props) => props.bgColor};
  font-size: 2.6rem;
  color: #fff;
  font-weight: 700;
  display: block;
  padding: 1.8rem 4rem;
  border-radius: 4rem;
  margin: 0 auto;
  margin-bottom: 60px;
`;

function Membership({ handleContact }) {
  return (
    <Segment bgColor={theme.colors.blue2}>
      <Container style={{ position: "relative" }}>
        <h2 className="title">퍼핀 네스트</h2>
        <Tickets>
          <Grid center={true}>
            <GridItem col={1 / 3} media={{ phone: 1 }}>
              <Ticket color="#1caae5">
                <img src="/image/membership/ticket_bg.png" alt="ticket_bg" />
                <div className="tier">정기 결제</div>
                <div className="num">
                  필터 배송 <span>포함</span>
                </div>
                <div className="price">월 33,000원</div>
              </Ticket>
            </GridItem>
          </Grid>
        </Tickets>

        <StyledGrid full>
          <GridItem col={5 / 23} media={{ phone: 1 / 2 }}>
            <div className="item item1">
              <img src="/image/membership/item1.png" alt="item1" />
              <p>AI 자율 환기</p>
            </div>
          </GridItem>
          <GridItem col={1 / 23} media={{ phone: 0 / 2 }}>
            <img src="/image/membership/plus.png" alt="plus" className="plus desktop" />
          </GridItem>
          <GridItem col={5 / 23} media={{ phone: 1 / 2 }}>
            <div className="item item2">
              <img src="/image/membership/item2.png" alt="item2" />
              <p>
                공기질 모니터링
                <br />
                서비스
              </p>
            </div>
          </GridItem>
          <GridItem col={1 / 23} media={{ phone: 0 / 2 }}>
            <img src="/image/membership/plus.png" alt="plus" className="plus desktop" />
          </GridItem>
          <GridItem col={5 / 23} media={{ phone: 1 / 2 }}>
            <div className="item item3">
              <img src="/image/membership/item3.png" alt="item3" />
              <p>
                프리미엄 공기질
                <br />
                센서 설치
              </p>
            </div>
          </GridItem>
          <GridItem col={1 / 23} media={{ phone: 0 / 2 }}>
            <img src="/image/membership/plus.png" alt="plus" className="plus desktop" />
          </GridItem>
          <GridItem col={5 / 23} media={{ phone: 1 / 2 }}>
            <div className="item item4">
              <img src="/image/membership/item4.png" alt="item4" />
              <p>
                환기 장치 필터
                <br />
                정기 배송
              </p>
            </div>
          </GridItem>
          <img src="/image/membership/plus_m.png" alt="plus" className="plus-m" />
          <div className="line1-m" />
          <div className="line2-m" />
        </StyledGrid>

        <h2>
          멤버십 가입 전,
          <br />
          <span style={{ fontWeight: 500, color: "#12628a" }}> 퍼핀플레이스를 먼저 경험</span>해 보세요.
        </h2>
        <Btn bgColor={theme.colors.mainBlue} onClick={() => handleContact(true)}>
          퍼핀플레이스 체험하기
        </Btn>
        <img src="/image/start/ticket.png" alt="ticket" className="ticket-image" />

        <img src="/image/membership/deco1.png" alt="deco1" className="deco1 desktop" />
        <img src="/image/membership/deco2.png" alt="deco2" className="deco2 desktop" />
      </Container>
    </Segment>
  );
}

export default Membership;
