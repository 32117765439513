import styled from "styled-components";
import media from "../../styles/media";
import { darken } from "polished";

export const InquiryBody = styled.div`
  padding: 1rem;

  ${media.phone`
        padding:1rem;                
    `}

  &.center {
    width: 200px;
    margin: 0 auto;
  }

  &.two-col {
    max-width: 50rem;
    margin: 0 auto;
  }

  .select-search__input {
    border-radius: 20px;
    padding: 1.5rem 1.5rem;
    font-size: 1.6rem;
  }
`;
export const Form = styled.form`
  width: 60%;
  margin: 0 auto;

  ${media.phone`
  width: 80%;              
    `}

  /* input[type="text"] {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 20px;
    padding: 1.5rem 1.5rem;
    font-size: 1.6rem;
    opacity: 0.9;
    border: 1.5px solid #d9d9d9;
  }

  input::placeholder {
    font-size: 1.6rem;
  } */

  input[type="text"] {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 20px;
    padding: 1.2rem 2rem;
    font-size: 2rem;
    opacity: 0.9;
    border: 1.5px solid #d9d9d9;
  }

  input::placeholder {
    font-size: 2rem;
  }

  input:focus {
    border-color: #1baae6;
  }
`;

export const StepTitle = styled.div`
  position: relative;
  height: 80px;
  h3 {
    color: #404040;
    font-size: 2.7rem;
    font-weight: 500;
    line-height: 1.2em;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0;
    white-space: nowrap;

    padding-bottom: 20px;
    margin-bottom: 20px;
    .step {
      color: #1caae5;
      font-weight: bold;
      display: block;
    }

    .header__msg {
      margin: 0;
      font-size: 2rem;
      color: #666666;
    }
  }
`;

export const InquiryWarp = styled.div`
  padding: 4rem 2rem 2rem 2rem;
  /* h3 {
    font-size: 2.7rem;
    font-weight: 300;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    padding-bottom: 20px;
    margin-bottom: 20px;
    .step {
      color: #1caae5;
      font-weight: bold;
      display: block;
    }

    .header__msg {
      margin: 0;
      font-size: 2rem;
      color: #666666;
    } */

  /* ${media.phone`
          br {
            display: none;
          }
        `} */
  /* } */

  h4 {
    font-weight: 300;
    font-size: 2.3rem;
    text-align: center;
  }

  img {
    max-width: 300px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }

  /* br {
    ${media.phone`
      display: none;
  `} */

  /* The container */
  .container {
    display: inline-block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 4px;
    margin-right: 28px;
    min-width: 104px;
    cursor: pointer;
    font-size: 2.4rem;
    font-weight: 400;
    color: #404040;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ${media.phone`
        display: block;
    `}
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 1rem;
    left: 0;
    height: 2.3rem;
    width: 2.3rem;
    /* background-color: #999999; */
    border: 1px solid #d9d9d9;
    border-radius: 100%;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #1caae5;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: white;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 0.5rem;
    left: 0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #1caae5;
  }
`;

export const ModalBtn = styled.button`
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  font-size: 2rem;
  font-weight: 700;
  background: ${(props) => (props.disabled ? "#2a5981" : "#1caae5")};
  color: ${(props) => (props.disabled ? "#c4c4c4" : "#FFF")};
  line-height: 3rem;
  border-radius: 4rem;
  padding: 1.3rem 4rem;
  transition: all 0.5s;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#2a5981" : darken(0.1, "#1caae5"))};
  }
`;
export const ErrorMsg = styled.p`
  font-size: 1.6rem;
  text-align: center;
  color: #d91604;
`;
export const NoErvMsg = styled.p`
  margin: 0;
  font-size: 1.8rem;
  font-weight: 400;
  color: #7f7f7f;
  line-height: 1.2em;
`;
export const DisableMsg = styled.p`
  font-size: 1.6rem;
  text-align: center;
  color: #7f7f7f;
`;

export const PostcodeWrap = styled.div`
  width: 500px !important;
  ${media.phone`
    width:100% !important;                
  `}
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 10px 10px 20px grey;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1001;
`;

export const Postcode = styled.div`
  position: relative;
  padding-top: 30px;
`;

export const PostCodeCloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const PolicyDesc = styled.div`
  width: 80%;
  margin: 0 auto;
  font-size: 1.8rem;
  color: #808080;
  font-weight: 400;
  a {
    text-decoration: none;
    color: #1caae5;
  }
  .refund {
    /* font-size: 1.6rem; */
    /* margin-left: 20px; */
    /* text-align: center; */
    text-align: right;
    font-weight: 300;
  }
`;

export const Desc = styled.div`
  width: 80%;
  margin: 0 auto;
  font-size: 1.8rem;
  color: #808080;
  font-weight: 400;
`;
