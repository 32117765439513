import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import InquiryForm1 from "./contactForm/inquiryForm1"; // step1 천장에 환기구
import InquiryForm2 from "./contactForm/inquiryForm2"; // step2 성함과 연락처
import InquiryForm3 from "./contactForm/inquiryForm3"; // step3 이메일
import InquiryForm4 from "./contactForm/inquiryForm4"; // step4 주소
import InquiryForm5 from "./contactForm/inquiryForm5"; // 약관 선택, 결제하기
import InquiryFormLast from "./contactForm/inquiryFormLast"; // 방문일 선택
import InquiryFormError from "./contactForm/inquiryFormError";

import ProgressBar from "./contactForm/ProgressBar";

import media from "../styles/media";
import useInput from "../hooks/useInput";

import { GET_PRODUCT } from "../graphql/query";
import { USER_INQUIRY, CREATE_ORDER, ORDER_PAID } from "../graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";

const Contact = ({ setIsOpen }) => {
  const [current, setCurrent] = useState(1);

  const totalInquiryNum = useRef(5);

  const [name, onChangeName, setName] = useInput("");
  const [phone, onChangePhone, setPhone] = useInput("");
  const [email, onChangeEmail, setEmail] = useInput("");

  const [address1, onChangeAddress1, setAddress1] = useInput("");
  const [address2, onChangeAddress2, setAddress2] = useInput("");
  const [kaptCode, setKaptCode] = useState("");
  const [apartment, setApartment] = useState("");
  const [isMarketingAgreed, setIsMarketingAgreed] = useState(false);

  const [apts, setApts] = useState([]); // 이전 step 조회시 확인 가능해야 함
  const [aptValue, setAptValue] = useState("");

  const [answerKey1, setAnswerKey1] = useState(null);

  const [errorMsg, setErrorMsg] = useState("");
  const [isNoErv, setIsNoErv] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);

  const [orderNum, setOrderNum] = useState("");

  // const PRODUCT_ID = "5f727cd71ef06a1bb4256b61"; // 테스트 100원
  const PRODUCT_ID = "5efe0a66dc87c5a7fe6c84bf"; //퍼핀플레이스

  const { loading, data } = useQuery(GET_PRODUCT, {
    variables: { productId: PRODUCT_ID },
    // fetchPolicy: "network-only",
  });

  const onClickPrevBtn = (current) => {
    if (current <= 1) return;
    setCurrent(current - 1);
  };

  const onChangeRadioBtn = (e) => {
    e.target.name === "inquiry1" && setAnswerKey1(e.target.value);
    setIsNoErv(false);
    if (current >= totalInquiryNum.current) return;
    if (e.target.name === "inquiry1" && e.target.value !== "0") {
      setIsNoErv(true);
    } else {
      setCurrent(current + 1);
    }
  };

  const gotoNextPage = () => {
    setCurrent(current + 1);
  };

  const myTrim = (x) => {
    return x.replace(/^\s+|\s+$/gm, "");
  };

  const [userInquiry, { data: userInquiryData }] = useMutation(USER_INQUIRY, {
    onError(error) {
      setIsDisabled(false);
      setErrorMsg("결제가 실패하였습니다.");
      console.error(error.message);
    },
  });

  const [createOrder, { data: createOrderData }] = useMutation(CREATE_ORDER, {
    onError(error) {
      setIsDisabled(false);
      setErrorMsg("결제가 실패하였습니다.");
      console.error(error.message);
    },
  });

  const [orderPaid, { data: orderPaidData }] = useMutation(ORDER_PAID, {
    onError(error) {
      setIsDisabled(false);
      setErrorMsg("결제가 실패하였습니다.");
      console.error(error.message);
    },
  });

  const onSubmit = async (e) => {
    setErrorMsg("");

    if (myTrim(name) === "" || myTrim(phone) === "" || myTrim(address1) === "" || myTrim(apartment) === "" || myTrim(address2) === "" || myTrim(email) === "") {
      setErrorMsg("입력되지 않은 값이 있습니다.");
      return;
    }

    if (isDisabled) return;
    setIsDisabled(true);

    await userInquiry({
      variables: {
        data: {
          name,
          phone,
          email,
          isMarketingAgreed,
        },
        kaptCode: kaptCode,
        floorName: address2,
      },
    });

    return;
  };

  // userInquiry 이후
  useEffect(() => {
    if (!userInquiryData) return;

    createOrder({
      variables: {
        userId: userInquiryData.userInquiry._id,
        data: { items: { product: PRODUCT_ID, qty: 1 } },
      },
    });
  }, [userInquiryData]);

  // createOrder 이후
  useEffect(() => {
    (async () => {
      try {
        if (!createOrderData || !createOrderData.createOrder.billings.length || !data) return;

        const paymentData = {
          amount: createOrderData.createOrder.billings[0].amount,
          buyer_name: name,
          buyer_tel: phone,
          buyer_email: email,
          buyer_addr: `${address1} ${apartment} ${address2}`,
          escrow: false,
          merchant_uid: createOrderData.createOrder.billings[0]._id,
          name: data.product.name,
          pay_method: "card",
          pg: "nice",
          m_redirect_url: `${window.location.origin}/payment/complete/mobile`,
        };

        const { IMP } = window;
        IMP.init(process.env.REACT_APP_IMP_USER_CODE);
        await IMP.request_pay(paymentData, async (respPay) => {
          if (!respPay.success) {
            setErrorMsg("결제가 실패하였습니다.");
            return;
          }

          orderPaid({
            variables: {
              orderId: createOrderData.createOrder._id,
              impUid: respPay.imp_uid,
            },
          });
        });
      } catch (e) {
        console.error(e);
        setIsDisabled(false);
      }
    })();
  }, [createOrderData, data]);

  // 아임포트 결제 이후

  // orderPaid
  useEffect(() => {
    if (!orderPaidData) return;
    setOrderNum(orderPaidData.orderPaid.visitRequest.visitId);
    setCurrent("LAST");
    setIsDisabled(false);
  }, [orderPaidData]);

  return (
    <>
      {!(current === "LAST" && !isScheduled) && (
        <CloseBtn onClick={() => setIsOpen(false)}>
          <img src="/image/modal/close_btn.png" alt="close" />
        </CloseBtn>
      )}
      {current && (
        <>
          <>
            <ProgressBar current={current} />

            {current === 1 && <InquiryForm1 answerKey1={answerKey1} onChangeRadioBtn={onChangeRadioBtn} isNoErv={isNoErv} />}
            {current === 2 && (
              <InquiryForm2 name={name} onChangeName={onChangeName} phone={phone} setPhone={setPhone} gotoNextPage={gotoNextPage} myTrim={myTrim} />
            )}
            {current === 3 && <InquiryForm3 email={email} onChangeEmail={onChangeEmail} gotoNextPage={gotoNextPage} myTrim={myTrim} />}
            {current === 4 && (
              <InquiryForm4
                address1={address1}
                setAddress1={setAddress1}
                apartment={apartment}
                setApartment={setApartment}
                address2={address2}
                onChangeAddress2={onChangeAddress2}
                apts={apts}
                setApts={setApts}
                aptValue={aptValue}
                setAptValue={setAptValue}
                kaptCode={kaptCode}
                setKaptCode={setKaptCode}
                gotoNextPage={gotoNextPage}
                myTrim={myTrim}
              />
            )}

            {current === 5 && (
              <InquiryForm5
                onChangeRadioBtn={onChangeRadioBtn}
                isMarketingAgreed={isMarketingAgreed}
                setIsMarketingAgreed={setIsMarketingAgreed}
                onSubmit={onSubmit}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                isDisabled={isDisabled}
              />
            )}
            {current === "LAST" && (
              <InquiryFormLast
                setIsOpen={setIsOpen}
                orderNum={orderNum}
                name={name}
                phone={phone}
                email={email}
                isScheduled={isScheduled}
                setIsScheduled={setIsScheduled}
              />
            )}
            {current === "ERROR" && <InquiryFormError setIsOpen={setIsOpen} />}
          </>
          {current !== 1 && current !== "LAST" && current !== "ERROR" && (
            <PrevBtn onClick={() => onClickPrevBtn(current)}>
              <img src="/image/modal/back_btn.png" alt="back" />
            </PrevBtn>
          )}
        </>
      )}
    </>
  );
};

export default Contact;

const CloseBtn = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  img {
    width: 16px !important;
  }
`;

const PrevBtn = styled.button`
  position: absolute;
  left: 3rem;
  bottom: 3rem;

  img {
    vertical-align: sub;
  }

  span {
    font-size: 2.2rem;
    font-weight: 300;
    margin-left: 10px;
  }
`;
