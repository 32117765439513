import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";
import Modal from "styled-react-modal";
import Header from "../components/header";
import MobileMenu from "../components/mobileMenu";
import Cover from "../components/cover";
import ServiceOverView from "../components/serviceOverView";
import Service from "../components/service";
import Start from "../components/start";
import Place from "../components/place";
import Review from "../components/review";
import Footer from "../components/footer";

import Contact from "../components/contact";
import { useParams } from "react-router-dom";
import media from "../styles/media";

import GlobalStyle from "../styles/globalStyle";
import theme from "../styles/theme";
import "../styles/react-select-search.css";

const StyledModal = Modal.styled`
  width: 600px;
  height: 500px;
  ${media.phone`
    height: 480px;          
  `}
  background-color: #FFF;
  border-radius: 4%;
  position:relative;   
  margin-left: 1rem;
  margin-right: 1rem;
`;

function Main() {
  const [isOpen, setIsOpen] = useState(false);
  const { mode } = useParams();

  const handleContact = (bool) => setIsOpen(bool);

  useEffect(() => {
    mode === "inquery" && setIsOpen(true);
  }, [mode]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <GlobalStyle />
        <Header />
        <MobileMenu />
        <Cover handleContact={handleContact} />
        <ServiceOverView />
        <Service index={1} />
        <Service index={2} />
        <Service index={3} />
        <Start handleContact={handleContact} />
        <Place />
        <Review />
        <Footer />

        <StyledModal isOpen={isOpen} setIsOpen={setIsOpen}>
          <Contact setIsOpen={setIsOpen} />
        </StyledModal>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default Main;
