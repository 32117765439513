import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "styled-react-modal";
import { InquiryWarp, InquiryBody, Form, ErrorMsg, ModalBtn, StepTitle } from "./contactForm/style";
import useInput from "../hooks/useInput";
import media from "../styles/media";
import moment from "moment";
import { PopupText, CalendlyEventListener } from "react-calendly";

import { GET_ORDERS_WITH_CONTACT_INFO } from "../graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";

function CheckOrder() {
  const [isShowCheckOrder, setIsShowCheckOrder] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [visitRequestData, setVisitRequestData] = useState(null);
  const [order, setOrder] = useState(null);

  const [getOrdersWithContactInfo] = useLazyQuery(GET_ORDERS_WITH_CONTACT_INFO, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (!data.getOrdersWithContactInfo || !data.getOrdersWithContactInfo.length) {
        setErrorMsg("신청 이력이 없습니다.");
        return;
      }
      const order = data.getOrdersWithContactInfo.find((order) => order.status === "paid");
      if (!order) {
        setErrorMsg("유효한 결제 이력이 없습니다.");
        return;
      }
      setOrder(data.getOrdersWithContactInfo[0]); //
    },
    onError(error) {
      console.error(error);
      setErrorMsg("퍼핀플레이스 체험 신청 조회를 실패하였습니다.");
    },
  });

  const myTrim = (x) => {
    return x.replace(/^\s+|\s+$/gm, "");
  };

  const onChangePhone = (e) => {
    const strOriginal = e.target.value.replace(/[^0-9]/g, "");
    setPhone(strOriginal.replace(/(^01.{1})([0-9]+)([0-9]{4})/, "$1-$2-$3"));
  };

  const onSubmit = async () => {
    setErrorMsg("");
    setVisitRequestData(null);
    if (myTrim(email) === "" || myTrim(phone) === "") {
      setErrorMsg("입력되지 않은 값이 있습니다.");
      return;
    }
    const phoneRole = /^\d{3}-\d{3,4}-\d{4}$/;
    if (!phoneRole.test(phone)) {
      setErrorMsg("휴대폰 번호가 유효하지 않습니다.");
      return false;
    }

    getOrdersWithContactInfo({ variables: { email: email, phone: phone } });
  };

  const onClickCloseBtn = () => {
    setIsShowCheckOrder(false);
    setErrorMsg("");
    setVisitRequestData(null);
  };

  const getPhoneStr = (phone) => {
    return phone.replace("010", "8210");
  };

  return (
    <>
      <CheckOrderBtn onClick={() => setIsShowCheckOrder(true)}>퍼핀플레이스 체험 신청 조회</CheckOrderBtn>
      <StyledModal isOpen={isShowCheckOrder} setIsOpen={setIsShowCheckOrder}>
        <CloseBtn onClick={onClickCloseBtn}>
          <img src="/image/modal/close_btn.png" alt="close" />
        </CloseBtn>
        <InquiryWarp>
          <StepTitle>
            <h3>퍼핀플레이스 체험 신청 조회</h3>
          </StepTitle>
          <InquiryBody>
            <Form onSubmit={onSubmit}>
              <input type="text" placeholder="이메일" value={email} onChange={(e) => setEmail(e.target.value)} />
              <input type="text" placeholder="휴대폰번호" value={phone} onChange={onChangePhone} maxLength={13} />
            </Form>

            {order && (
              <OrderItem>
                <p>신청자 : {order.visitRequest.name}</p>
                <p>연락처 : {order.visitRequest.phone}</p>
                <p>이메일 : {order.visitRequest.email}</p>
                {order.billings.map((billing, index) =>
                  billing.status !== "paid" ? (
                    <div key={index}></div>
                  ) : (
                    <div key={index}>
                      <p>결제금액 : {billing.amount}원</p>
                      <p>결제일시 : {moment(billing.paidAt).format("YYYY-MM-DD, h:mm a")}</p>
                    </div>
                  )
                )}
                <p>
                  방문 예정 일시 :{" "}
                  {order.visitRequest.inviteeStartTime ? (
                    moment(order.visitRequest.inviteeStartTime).format("YYYY-MM-DD, h:mm a")
                  ) : (
                    <>
                      {" "}
                      지정된 예약이 없습니다.
                      <CalendlyEventListener onEventScheduled={() => getOrdersWithContactInfo({ variables: { email: email, phone: phone } })}>
                        <CalendlyBtn>
                          <PopupText
                            url="https://calendly.com/puffinplace/air_consulting"
                            text="방문 예약신청"
                            prefill={{
                              name: order.visitRequest.name,
                              email: order.visitRequest.email,
                              customAnswers: {
                                a1: getPhoneStr(order.visitRequest.phone),
                                a2: order.visitRequest.visitId,
                              },
                            }}
                          />
                        </CalendlyBtn>
                      </CalendlyEventListener>
                    </>
                  )}{" "}
                </p>
              </OrderItem>
            )}

            {visitRequestData && (
              <VisitRequestItem>
                <p>신청자 : {visitRequestData.name}</p>
                <p>연락처 : {visitRequestData.phone}</p>
                <p>이메일 : {visitRequestData.email}</p>
                <p>결제일시 : {moment(visitRequestData.createdAt).format("YYYY-MM-DD, h:mm a")}</p>
              </VisitRequestItem>
            )}

            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
            <ModalBtn onClick={onSubmit}>조회</ModalBtn>
          </InquiryBody>
        </InquiryWarp>
      </StyledModal>
    </>
  );
}

function InviteeStartTime({ reservation }) {
  const [validStartTime, setValidStartTime] = useState([]);
  useEffect(() => {
    const startTime = [];
    reservation.forEach((cur) => {
      if (cur.status === "invitee.created") {
        startTime.push(moment(cur.inviteeStartTime).format("YYYY-MM-DD, h:mm a"));
      }
    });
    reservation.forEach((cur) => {
      if (cur.status === "invitee.canceled") {
        startTime.splice(moment(cur.inviteeStartTime).format("YYYY-MM-DD, h:mm a"), 1);
      }
    });
    setValidStartTime(startTime);
  }, []);

  return (
    <div style={{ marginTop: 10 }}>
      {validStartTime.map((cur, index) => (
        <p key={index}> 방문예정일시 : {cur}</p>
      ))}
      {validStartTime.length > 1 && <p style={{ color: "red" }}>2회 이상 방문 예약이 잡혔습니다. 재조정 연락을 드리겠습니다. </p>}
    </div>
  );
}

const StyledModal = Modal.styled`
  width: 600px;
  height: 500px;
  ${media.phone`
  height: 530px;          
  `}
  background-color: #FFF;
  border-radius: 4%;
  position:relative;   
  margin-left: 1rem;
  margin-right: 1rem;
`;

const CloseBtn = styled.div`
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  cursor: pointer;
`;

const CheckOrderBtn = styled.div`
  font-size: 2.2rem;
  color: #1baae6;
  cursor: pointer;
  margin-top: 2rem;
  max-width: 220px;
  display: block;
  margin: 20px auto 0 auto;
`;

const VisitRequestItem = styled.div`
  p {
    margin: 0;
    font-size: 2rem;
  }
`;

const OrderItem = styled.div`
  p {
    margin: 0;
    font-size: 2rem;
  }
`;

const CalendlyBtn = styled.span`
  a {
    color: #1baae6;
    margin-left: 10px;
    text-decoration: none;
  }
`;

export default CheckOrder;
