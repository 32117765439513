import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
const GRAPHQL_ENDPOINT = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/graphql`;
// const GRAPHQL_ENDPOINT = "https://clairynet-dev.com/graphql";

const client = new ApolloClient({
  link: createHttpLink({ uri: GRAPHQL_ENDPOINT }),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default client;
