import React from "react";
import styled from "styled-components";
import media from "../../styles/media";

function ProgressBar({ current }) {
  const barWidthArr = ["20%", "40%", "60%", "80%", "100%"];

  if (!(current >= 1 && current <= 5)) return null;

  return (
    <ProgressBarWrap>
      <LabelUl>
        <li style={current === 1 ? { color: "#1baae6" } : {}}>STEP 1</li>
        <li style={current === 2 ? { color: "#1baae6" } : {}}>STEP 2</li>
        <li style={current === 3 ? { color: "#1baae6" } : {}}>STEP 3</li>
        <li style={current === 4 ? { color: "#1baae6" } : {}}>STEP 4</li>
        <li style={current === 5 ? { color: "#1baae6" } : {}}>STEP 5</li>
      </LabelUl>
      <BarWrap>
        <Bar barWidth={barWidthArr[current - 1]} />
      </BarWrap>
    </ProgressBarWrap>
  );
}

export default ProgressBar;

const ProgressBarWrap = styled.div`
  /* margin: 30px 60px 0 30px; */
  /* margin: 36px auto 0px auto; */
  margin: 30px 60px 0 60px;
  ${media.phone`
   margin: 36px auto 0px auto;  
  `}
`;

const LabelUl = styled.ul`
  overflow: hidden;

  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    width: 20%;
    float: left;
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    /* color: #7f7f7f; */
    color: #aaa;
  }
`;

const BarWrap = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 5px;
  background-color: #dcdcdc;
`;

const Bar = styled.div`
  width: ${(props) => props.barWidth};
  height: 5px;
  background-color: #1baae6;
  transition: width 0.2s ease-out;
`;
