import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import Header from "../components/header";
import MobileMenu from "../components/mobileMenu";
import Footer from "../components/footer";
import { Container } from "../styles/common";

import { Grid, GridItem } from "styled-grid-responsive";
import media from "../styles/media";

import { useForm } from "react-hook-form";

import GlobalStyle from "../styles/globalStyle";
import theme from "../styles/theme";
import { useAlert } from "react-alert";
import { HELP_DESK_QUESTION } from "../graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

const Segment = styled.div`
  background-color: #f7f5ee;

  form {
    width: 800px;
    margin: 0 auto;

    ${media.tablet`
      width: 80%;
    `}
  }
  h2 {
    text-align: center;
    margin-bottom: 80px;
  }
  textarea {
    height: 300px;
  }
  textarea,
  input[type="text"] {
    width: 100%;
    padding: 10px;
    line-height: 1.8em;
    border: 1px solid #e4e4e6;
    margin-bottom: 20px;
    font-weight: 300;
    border-radius: 10px;

    &:focus {
      outline: none !important;
      border: 1px solid #1baae6;
    }
  }
  label {
    position: relative;
    line-height: 2.8em;
    &:after {
      position: absolute;
      content: "*";
      top: -3px;
      right: -13px;
      color: #1baae6;
      font-size: 3rem;
    }
  }

  input[type="submit"] {
    background-color: #1baae6;
    font-size: 2.6rem;
    color: #fff;
    font-weight: 700;
    display: inline-block;
    padding: 1.8rem 4rem;
    border-radius: 4rem;
    transition: all 0.5s;
    width: 200px;
    display: block;
    margin: 0 auto;
    margin-top: 60px;
    &:hover {
      background-color: darken(0.1, #1baae6);
    }
  }
`;

const KakaoAsk = styled.div`
  border-bottom: 1px solid #e4e4e6;
  padding-bottom: 20px;
  margin-bottom: 40px;
  position: relative;

  p {
    font-size: 2rem;
    line-height: 1.4em;
    span {
      color: #1baae6;
    }
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2.2rem;
    font-weight: 500;
    padding: 14px 70px 14px 30px;
    border-radius: 30px;
    color: #3c1e1e;
    background-color: #fceb4f;
    text-decoration: none;
    background-image: url("/image/support/kakao.png");
    background-repeat: no-repeat;
    background-position: 94% 58%;
    ${media.tablet`
      margin-top: 20px;
      display:block;
      position: relative;
    `}
  }
`;

const ErrorMsg = styled.p`
  color: #1baae6;
  margin-top: -20px;
  font-size: 1.8rem;
`;

function Support() {
  const { register, handleSubmit, errors, reset } = useForm();
  const alert = useAlert();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [helpDeskQuestion] = useMutation(HELP_DESK_QUESTION, {
    onCompleted(data) {
      reset();
      alert.show("전송하였습니다", { closeCopy: "확인" });
    },
    onError(error) {
      console.error(error);
      alert.show("전송에 실패하였습니다", { closeCopy: "확인" });
    },
  });

  const onSubmit = async (data) => {
    helpDeskQuestion({
      variables: {
        title: `[랜딩페이지-고객센터] ${data.name} 님의 문의사항입니다.(phone:${data.phone} / email:${data.email}) `,
        message: data.ask,
      },
    });
  };

  useEffect(() => {
    (async () => {
      if (window.Alli) {
        document.querySelector("#alli-container").style.display = "block";
        window.Alli.event();
        return;
      }

      const script = document.createElement("script");
      script.src = "https://sdk.alli.ai/latest/alli.js";
      script.async = true;
      script.onload = () => {
        window.Alli.initialize({
          apiKey: process.env.REACT_APP_ALLI_CHATBOT_KEY,
          debug: false, // if you turn this on, debug info will be printed in the console
          backButton: true, // optional, default: true, If you want to remove the back button on the header, turn off this option.
          providerLink: true, // optional, default: true, If you want to remove the link on the Alli provider logo, turn off this option.
          styleOptions: {
            // optional, to customize style.
            conversationContainer: {
              right: 50, // offset from the right (applied when SDK position is set to right on project settings)
              left: 50, // offset from the left (applied when SDK position is set to left on project settings)
              bottom: 50, // offset from the bottom
            },
          },
        });
        window.Alli.event();
      };
      document.body.appendChild(script);
    })();

    return function cleanup() {
      document.querySelector("#alli-container").style.display = "none";
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <MobileMenu />
      <Segment>
        <Container style={{ position: "relative", paddingTop: "80px", paddingBottom: "120px" }}>
          <h2>고객센터</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <KakaoAsk>
              <p>
                서비스와 관련하여 궁금하신 점이 있으시면 문의해주세요.
                <br />
                퍼핀플레이스 고객센터에서 확인 후 빠르게 연락드립니다. <br />
                <span>평일 오전 9시 - 오후 7시</span>
              </p>
              <a href="http://pf.kakao.com/_Tygcxb" target="_blank" rel="noopener noreferrer">
                카카오톡으로 문의하기
              </a>
            </KakaoAsk>
            <Grid>
              <GridItem col={1 / 5} media={{ phone: 1 }}>
                <label htmlFor="ask">문의사항</label>
              </GridItem>
              <GridItem col={4 / 5} media={{ phone: 1 }}>
                <textarea id="ask" name="ask" defaultValue="" ref={register({ required: true })} />
                {errors.ask && <ErrorMsg>문의사항을 입력해주세요.</ErrorMsg>}
              </GridItem>
            </Grid>
            <Grid>
              <GridItem col={1 / 5} media={{ phone: 1 }}>
                <label htmlFor="name">이름</label>
              </GridItem>
              <GridItem col={4 / 5} media={{ phone: 1 }}>
                <input type="text" id="name" name="name" defaultValue="" ref={register({ required: true })} />
                {errors.name && <ErrorMsg>이름을 입력해주세요.</ErrorMsg>}
              </GridItem>
            </Grid>
            <Grid>
              <GridItem col={1 / 5} media={{ phone: 1 }}>
                <label htmlFor="phone">휴대폰</label>
              </GridItem>
              <GridItem col={4 / 5} media={{ phone: 1 }}>
                <input type="text" id="phone" name="phone" defaultValue="" ref={register({ required: true })} />
                {errors.phone && <ErrorMsg>휴대폰 번호를 입력해주세요.</ErrorMsg>}
              </GridItem>
            </Grid>
            <Grid>
              <GridItem col={1 / 5} media={{ phone: 1 }}>
                <label htmlFor="email">이메일</label>
              </GridItem>
              <GridItem col={4 / 5} media={{ phone: 1 }}>
                <input type="text" id="email" name="email" defaultValue="" ref={register({ required: true })} />
                {errors.email && <ErrorMsg>이메일을 입력해주세요.</ErrorMsg>}
              </GridItem>
            </Grid>
            <input type="submit" value="전송하기" />
          </form>
        </Container>
      </Segment>
      <Footer />
    </ThemeProvider>
  );
}

export default Support;
