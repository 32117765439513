import gql from "graphql-tag";

//TODO: 삭제
// export const CREATE_USER = gql`
//   mutation createUser($organizationId: ID!, $data: UserInput!) {
//     createUser(organizationId: $organizationId, data: $data) {
//       _id
//     }
//   }
// `;

export const USER_INQUIRY = gql`
  mutation userInquiry($data: UserInput!, $kaptCode: String!, $floorName: String!) {
    userInquiry(data: $data, kaptCode: $kaptCode, floorName: $floorName) {
      _id
      floors {
        _id
        name
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($userId: ID!, $data: OrderInput!) {
    createOrder(userId: $userId, data: $data) {
      _id
      customerUid
      billings {
        _id
        type
        amount
        status
        createdAt
      }
    }
  }
`;

export const ORDER_PAID = gql`
  mutation orderPaid($orderId: ID!, $impUid: String!) {
    orderPaid(orderId: $orderId, impUid: $impUid) {
      _id
      visitRequest {
        _id
        name
        email
        phone
        status
        visitId
      }
    }
  }
`;

export const HELP_DESK_QUESTION = gql`
  mutation helpDeskQuestion($title: String!, $message: String!) {
    helpDeskQuestion(title: $title, message: $message)
  }
`;

export const CREATE_VISIT_REQUEST_FOR_FLOOR = gql`
  mutation createVisitRequestForFloor($floorId: ID!, $data: VisitRequestInput!) {
    createVisitRequestForFloor(floorId: $floorId, data: $data) {
      _id
    }
  }
`;

export const RESERVE_VISIT_REQUEST = gql`
  mutation reserveVisitRequest($visitRequestId: ID!) {
    reserveVisitRequest(visitRequestId: $visitRequestId) {
      _id
      inviteeStartTime
    }
  }
`;

export const UPDATE_VISIT_REQUEST = gql`
  mutation updateVisitRequest($visitRequestId: ID!, $data: VisitRequestInput!) {
    updateVisitRequest(visitRequestId: $visitRequestId, data: $data) {
      _id
      inviteeStartTime
    }
  }
`;
