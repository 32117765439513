import React from "react";
import moment from "moment";
import "moment/locale/ko";

function FilterStepSuccess({ name, phone, email, dong, ho, inviteeStartTime }) {
  return (
    <>
      <h3 className="page-subtitle">필터 방문 교체</h3>
      <h2 className="page-title">신청이 완료되었습니다.</h2>
      <div className="form text-gray-500">
        {inviteeStartTime && (
          <h3 className="text-xl font-bold mb-6">{moment(inviteeStartTime).locale("ko").format("YYYY년 M월 DD일 a h시 m분")}에 방문 예정입니다.</h3>
        )}

        <p className="success-desc">이름: {name}</p>
        <p className="success-desc">휴대폰: {phone}</p>
        <p className="success-desc">이메일: {email}</p>
        <p className="success-desc">
          상세주소: {dong}동 {ho}호
        </p>
      </div>
    </>
  );
}

export default FilterStepSuccess;
