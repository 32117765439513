import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import "moment/locale/ko";

function FilterStep2({ submitInviteeData, inviteeStartTime, setInviteeStartTime, scheduleProposal, scheduleList, isModify = false }) {
  const [pageNum, setPageNum] = useState(1);
  const [currentDate, setCurrentDate] = useState(); // 선택된 날짜
  const [currentDateTimes, setCurrentDateTimes] = useState([]); // 선택된 날짜의 시간 목록
  const [inviteeEndTime, setInviteeEndTime] = useState();

  const [availableDates, setAvailableDates] = useState([]);
  const changeDate = (date) => {
    setCurrentDate(date);
  };

  useEffect(() => {
    if (scheduleProposal && !scheduleProposal.length) setPageNum(2);
  }, [scheduleProposal]);

  useEffect(() => {
    if (!scheduleList) return;
    const tempDates = [];
    scheduleList.forEach((cur, index) => {
      const date = new Date(cur.inviteeStartTime);
      if (index === 0) {
        setCurrentDate(date);
      }
      const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
      tempDates.push(dateStr);
    });
    if (isModify && inviteeStartTime) {
      setCurrentDate(inviteeStartTime);
    }

    setAvailableDates([...new Set(tempDates)]);
  }, [scheduleList, isModify]);

  // 선택된 날짜의 일시 설정
  useEffect(() => {
    if (!currentDate || !scheduleList) return;
    const tempDates = [];
    scheduleList.forEach((cur, index) => {
      const date = new Date(cur.inviteeStartTime);
      if (date.getFullYear() === currentDate.getFullYear() && date.getMonth() === currentDate.getMonth() && date.getDate() === currentDate.getDate()) {
        tempDates.push(date);
      }
      setCurrentDateTimes(tempDates);
    });
  }, [currentDate, scheduleList]);

  const onClickNextButton = () => {
    setPageNum(2);
  };

  const checkTileDisabled = (date) => {
    const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    return !availableDates.includes(dateStr);
  };

  const onClickDateTime = (startDateTime, endDateTime = "") => {
    setInviteeStartTime(startDateTime);
    if (endDateTime) {
      setInviteeEndTime(endDateTime);
    } else {
      setInviteeEndTime(scheduleList.find((cur) => moment(cur.inviteeStartTime).isSame(startDateTime)).inviteeEndTime);
    }
  };

  const onClickSubmit = () => {
    submitInviteeData({ inviteeStartTime: inviteeStartTime, inviteeEndTime: inviteeEndTime });
  };

  return (
    <>
      <h3 className="page-subtitle">필터 방문 교체</h3>
      <h2 className="page-title">방문일을 선택해 주세요.</h2>

      <div className="form relative">
        {pageNum === 2 && !!scheduleProposal.length && (
          <div className="absolute left-2 top-3 cursor-pointer" onClick={() => setPageNum(1)}>
            <img src="/image/apartment/common/back_btn.png" alt="back" />
          </div>
        )}

        {/* step1  */}
        {pageNum === 1 && !!scheduleProposal?.length && (
          <div>
            {scheduleProposal.map((cur, index) => (
              <div
                className={"date-item " + (moment(cur.inviteeStartTime).isSame(inviteeStartTime) && "active")}
                key={index}
                onClick={() => onClickDateTime(cur.inviteeStartTime, cur.inviteeEndTime)}
              >
                {moment(cur.inviteeStartTime).locale("ko").format("M월 D일 dddd a h시 m분")}
              </div>
            ))}
            <p className="text-right text-gray-400 text-xl cursor-pointer mt-4" onClick={onClickNextButton}>
              다른 방문일 선택 &gt;
            </p>
          </div>
        )}

        {/* step2 */}
        {pageNum === 2 && (
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 calendar-wrap pt-10">
              <Calendar
                onChange={(date) => changeDate(date)}
                value={currentDate}
                tileDisabled={({ date }) => {
                  return checkTileDisabled(date);
                }}
              />
            </div>
            <div className="flex-1 mt-6 md:pl-4 md:mt-0">
              <p>{moment(currentDate).locale("ko").format("YYYY년 M월 D일 dddd")}</p>

              <div className="date-list">
                {currentDateTimes.map((cur, index) => (
                  <div className={"date-item " + (moment(cur).isSame(inviteeStartTime) && "active")} key={index} onClick={() => onClickDateTime(cur)}>
                    {moment(cur).locale("ko").format("a h시 m분")}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <button
          disabled={!inviteeStartTime}
          className={"bg-main-blue text-white px-14 py-2 rounded block mx-auto mt-10 " + (!inviteeStartTime && "bg-main-blue-dark text-gray-300")}
          onClick={onClickSubmit}
        >
          확인
        </button>
      </div>
    </>
  );
}

export default FilterStep2;
