import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ModalProvider } from "styled-react-modal";
import Modal from "styled-react-modal";
import { InquiryWarp, InquiryBody, ModalBtn, StepTitle, Desc } from "../components/contactForm/style";
import styled from "styled-components";
import { PopupText, CalendlyEventListener } from "react-calendly";

import GlobalStyle from "../styles/globalStyle";
import theme from "../styles/theme";
import media from "../styles/media";

import { GET_ORDER_FROM_BILLLING } from "../graphql/query";
import { ORDER_PAID } from "../graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";

const useParams = () => {
  return new URLSearchParams(useLocation().search);
};

function PaymentCompleteMobile() {
  const param = useParams();
  const history = useHistory();

  const [visitId, setVisitId] = useState("");
  const [phoneStr, setPhoneStr] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("LOADING"); // SUCCESS | FAILED | LOADING
  const [isScheduled, setIsScheduled] = useState(false);

  const { data: orderData } = useQuery(GET_ORDER_FROM_BILLLING, {
    variables: {
      billingId: param.get("merchant_uid"),
    },
    onError(error) {
      console.error(error);
      setStatus("FAILED");
    },
  });

  const [orderPaid, { data: orderPaidData }] = useMutation(ORDER_PAID, {
    onCompleted(data) {
      const phoneStr = data.orderPaid.visitRequest.phone.replace("010", "8210");
      setPhoneStr(phoneStr);
      setName(data.orderPaid.visitRequest.name);
      setEmail(data.orderPaid.visitRequest.email);
      setVisitId(data.orderPaid.visitRequest.visitId);
      setStatus("SUCCESS");
    },
    onError(error) {
      console.error(error);
      setStatus("FAILED");
    },
  });

  useEffect(() => {
    if (param.get("imp_success") !== "true") {
      return setStatus("FAILED");
    }
    if (!orderData) return;
    orderPaid({
      variables: {
        orderId: orderData.getOrderFromBillling._id,
        impUid: param.get("imp_uid"),
      },
    });
  }, [orderData]);

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <GlobalStyle />
        <StyledModal isOpen={true}>
          {status === "LOADING" ? (
            <InquiryWarp>
              <StepTitle>
                <h3>처리중 입니다.</h3>
              </StepTitle>
            </InquiryWarp>
          ) : status === "FAILED" ? (
            <InquiryWarp>
              <StepTitle>
                <h3>결제를 실패하였습니다.</h3>
              </StepTitle>
              <InquiryBody>
                <ModalBtn
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  닫기
                </ModalBtn>
              </InquiryBody>
            </InquiryWarp>
          ) : (
            <InquiryWarp>
              <StepTitle>
                <h3>신청해 주셔서 감사합니다.</h3>
              </StepTitle>
              <InquiryBody>
                <Desc>
                  <h4>
                    퍼핀플레이스 체험 신청이 완료되었습니다. <br />
                    (주문번호 : {visitId})
                  </h4>
                  <br />
                  {!isScheduled ? (
                    <>
                      <VisitRequestTitle>방문 예약신청을 해주세요.</VisitRequestTitle>
                      <br />
                      <CalendlyEventListener onEventScheduled={() => setIsScheduled(true)}>
                        <StyledModalBtn>
                          <PopupText
                            url="https://calendly.com/puffinplace/air_consulting"
                            text="방문 예약신청"
                            prefill={{
                              name: name,
                              email: email,
                              customAnswers: {
                                a1: phoneStr,
                                a2: visitId,
                              },
                            }}
                          />
                        </StyledModalBtn>
                      </CalendlyEventListener>
                    </>
                  ) : (
                    <>방문 예약신청이 완료되었습니다.</>
                  )}
                </Desc>
                {isScheduled && (
                  <ModalBtn
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    닫기
                  </ModalBtn>
                )}
              </InquiryBody>
            </InquiryWarp>
          )}
        </StyledModal>
      </ModalProvider>
    </ThemeProvider>
  );
}

export default PaymentCompleteMobile;

const VisitRequestTitle = styled.h3`
  text-align: center;
  font-size: 2.7rem;
  color: #404040;
  font-weight: 400;
`;

const StyledModal = Modal.styled`
  width: 600px;
  height: 500px;
  ${media.phone`
  height: 530px;          
  `}
  background-color: #FFF;
  border-radius: 4%;
  position:relative;   
  margin-left: 1rem;
  margin-right: 1rem;
`;

const StyledModalBtn = styled(ModalBtn)`
  a {
    color: #fff !important;
    text-decoration: none;
  }
`;
