import { createGlobalStyle } from "styled-components";
import media from "./media";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;500;700&display=swap');
 
  *, *::before, *::after {
    box-sizing: border-box;
    font-family: 'Noto Sans KR'; 
  }
  
  html {
    margin: 0; 
    font-size: 8px;
    ${media.phone`
      font-size: 6px;
    `}
    /* font-size: 10px;
    ${media.phone`
      font-size: 7px;
    `}  */
  }

  body {
    /* font-family: Slack-Circular-Pro, "Helvetica Neue", Helvetica, "Segoe UI",
      Tahoma, Arial, sans-serif; */
      font-family: 'Noto Sans KR', sans-serif;
    font-weight: 300;
    line-height: 1.7;
    margin: 0; 
    padding: 0;
    color: #222222;
    overflow-x:hidden;
  }
  p{
    color: #1c1c1c;
  }

  b{
    font-weight: 700;
  }

  input:focus {outline:0;}

  h2{
    font-size :3.8rem;
    font-weight: 700;
    line-height: 1.4em;
  }

  button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .desktop{
    display:block;
    ${media.phone`
      display:none;
    `} 
  }

  .phone{
    display:none;
    ${media.phone`
      display:block;
    `} 
  }

  .onlyDesktop{
    display:block;
    ${media.tablet`
      display:none;
    `} 
  }

  .tabletAndPhone{
    display:none;
    ${media.tablet`
      display:block;
    `} 
  }


  #alli-container {
    .alli-sdk-Paper{
      width: 300px !important;
    }
    ${media.phone`
      .conversation-container{
        right: 5px !important;
        bottom: 5px !important; 
      }
      .alli-sdk-Paper{
        width: 260px !important;
      }
    `} 
  } 
  .MuiDialogContentText-root {
    font-size: 2.4rem !important;
  }
  .MuiButton-label{
    font-size: 2rem;
  }

`;

export default GlobalStyle;
