import React from "react";
import { Grid, GridItem } from "styled-grid-responsive";
import styled from "styled-components";
import { InquiryWarp, InquiryBody, NoErvMsg, StepTitle } from "./style";
import media from "../../styles/media";

function InquiryForm1({ answerKey1, onChangeRadioBtn, isNoErv }) {
  return (
    <InquiryWarp>
      <StepTitle>
        <h3>
          <span className="step">STEP 1</span> <span>천장에 환기구가 보이시나요?</span>
        </h3>
      </StepTitle>
      <InquiryBody>
        <img src="/image/contact1.png" alt="contact1" />
        <StyledGrid gutter={0}>
          <GridItem className="grid__item-1" col={1 / 2}>
            <label className="container">
              네, 보여요!
              <input
                type="radio"
                name="inquiry1"
                value="0"
                id="inquiry1-1"
                onClick={(e) => onChangeRadioBtn(e)}
                onChange={(e) => onChangeRadioBtn(e)}
                checked={answerKey1 === "0"}
              />
              <span className="checkmark"></span>
            </label>
          </GridItem>
          <GridItem className="grid__item-2" col={1 / 2}>
            <label className="container">
              아니요, 안보여요.
              <input
                type="radio"
                name="inquiry1"
                value="1"
                id="inquiry1-2"
                onClick={(e) => onChangeRadioBtn(e)}
                onChange={(e) => onChangeRadioBtn(e)}
                checked={answerKey1 === "1"}
              />
              <span className="checkmark"></span>
            </label>
            {isNoErv && (
              <NoErvMsg>
                죄송합니다. 퍼핀 플레이스는 전열교환기가
                <br />
                설치된 가정에서만 이용 가능합니다.
              </NoErvMsg>
            )}
          </GridItem>
        </StyledGrid>
      </InquiryBody>
    </InquiryWarp>
  );
}

export default InquiryForm1;

const StyledGrid = styled(Grid)`
  ${media.phone`
    max-width: 320px;
    margin: 0 auto;
  `}

  .grid__item-1 {
    text-align: right;
    ${media.phone`
      text-align: left;
    `}
  }

  .grid__item-2 {
    padding-left: 0;
  }
`;
