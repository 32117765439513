import React, { useState } from "react";
import { InquiryWarp, InquiryBody, Form, ModalBtn, ErrorMsg, StepTitle, PolicyDesc } from "./style";
import { policy1, policy2, marketingPolicy } from "../../data/policy";
import Modal from "styled-react-modal";
import styled from "styled-components";

function InquiryForm5({ isMarketingAgreed, setIsMarketingAgreed, onSubmit, errorMsg, setErrorMsg, isDisabled }) {
  const [isShowPolicy1, setIsShowPolicy1] = useState(false);
  const [isShowPolicy2, setIsShowPolicy2] = useState(false);
  const [isShowMarketingPolicy, setIsShowMarketingPolicy] = useState(false);
  const [isShowRefundInfo, setIsShowRefundInfo] = useState(false);

  const [isPolicyChecked, setIsPolicyChecked] = useState(false);

  const onClickSubmitBtn = (e) => {
    e.preventDefault();
    setErrorMsg("");

    if (!isPolicyChecked) {
      setErrorMsg("약관에 동의해주세요.");
      return;
    }

    onSubmit();
  };

  return (
    <>
      <InquiryWarp>
        <StepTitle style={{ height: 44 }}>
          <h3>체험비 30,000원 결제가 진행됩니다.</h3>
        </StepTitle>
        <InquiryBody>
          <PolicyDesc>
            <input type="checkbox" onChange={() => setIsPolicyChecked(!isPolicyChecked)} checked={isPolicyChecked} />
            <a
              href="# "
              onClick={(e) => {
                e.preventDefault();
                setIsShowPolicy1(true);
              }}
            >
              전자금융거래 기본약관
            </a>{" "}
            및{" "}
            <a
              href="# "
              onClick={(e) => {
                e.preventDefault();
                setIsShowPolicy2(true);
              }}
            >
              개인정보 처리방침
            </a>
            에 동의합니다.
            <br />
            <input type="checkbox" onChange={() => setIsMarketingAgreed(!isMarketingAgreed)} checked={isMarketingAgreed} />
            <a
              href="# "
              onClick={(e) => {
                e.preventDefault();
                setIsShowMarketingPolicy(true);
              }}
            >
              마케팅 정보 활용
            </a>
            에 동의합니다.(선택)
            <br />
            <br />
            <div className="refund">
              <a
                href="# "
                onClick={(e) => {
                  e.preventDefault();
                  setIsShowRefundInfo(true);
                }}
              >
                * 체험비 환불 규정 안내
              </a>
            </div>
            <br />
          </PolicyDesc>
          {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
          <ModalBtn onClick={(e) => onClickSubmitBtn(e)} disabled={isDisabled} id="inquiry_submit_btn" id="inquiry5">
            결제하기
          </ModalBtn>
        </InquiryBody>
      </InquiryWarp>
      <StyledModal isOpen={isShowPolicy1} setIsOpen={setIsShowPolicy1}>
        <h4>전자금융거래 기본약관</h4>
        <CloseBtn onClick={() => setIsShowPolicy1(false)}>
          <img src="/image/modal/close_btn.png" alt="close" />
        </CloseBtn>
        <Form style={{ width: "90%" }}>
          <StyledTextArea readOnly defaultValue={policy1} />
        </Form>
      </StyledModal>
      <StyledModal isOpen={isShowPolicy2} setIsOpen={setIsShowPolicy2}>
        <h4>개인정보 처리방침</h4>
        <CloseBtn onClick={() => setIsShowPolicy2(false)}>
          <img src="/image/modal/close_btn.png" alt="close" />
        </CloseBtn>
        <Form style={{ width: "90%" }}>
          <StyledTextArea readOnly defaultValue={policy2} />
        </Form>
      </StyledModal>
      <StyledModal isOpen={isShowMarketingPolicy} setIsOpen={setIsShowMarketingPolicy}>
        <h4>마케팅 정보 활용 (선택)</h4>
        <CloseBtn onClick={() => setIsShowMarketingPolicy(false)}>
          <img src="/image/modal/close_btn.png" alt="close" />
        </CloseBtn>
        <Form style={{ width: "90%" }}>
          <StyledTextArea readOnly defaultValue={marketingPolicy} />
        </Form>
      </StyledModal>
      <StyledModal isOpen={isShowRefundInfo} setIsOpen={setIsShowRefundInfo}>
        <h4>체험비 환불 규정 안내</h4>
        <CloseBtn onClick={() => setIsShowRefundInfo(false)}>
          <img src="/image/modal/close_btn.png" alt="close" />
        </CloseBtn>
        <Form style={{ width: "90%" }}>
          <StyledTextArea
            readOnly
            defaultValue={`
            * 방문 예정일 7일 전 취소 시 전액 환급 
            * 방문 예정일 3일 전 취소 시 요금의 10% 공제 후 환급 
            * 방문 예정일 1일 전 취소 시 요금의 20% 공제 후 환급 
            * 방문 예정일 당일 취소 시 요금의 30% 공제 후 환급 
            * 체험 서비스 개시 후 환불 요청 시, 환급 불가     
          `}
          />
        </Form>
      </StyledModal>
    </>
  );
}

export default InquiryForm5;

const StyledModal = Modal.styled`
  width: 700px;
  height: 400px;
  background-color: #FFF;
  border-radius: 20px;
  position:relative;   
  margin-left: 1rem;
  margin-right: 1rem;

  h4{
    font-size: 2.2rem;
    text-align: center;
    margin-top: 2.4rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  textarea {
    width: 100%;
    height: 100%;
    font-weight: 100; 
    border: none;
    padding: 0 1rem;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  cursor: pointer;
  img {
    width: 16px !important;
  }
`;

const StyledTextArea = styled.textarea`
  min-height: 300px;
  resize: none;

  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
`;
