import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../styles/common";
import { Grid, GridItem } from "styled-grid-responsive";
import theme from "../styles/theme";

import YouTube from "react-youtube";
import media from "../styles/media";

const YouTubeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  border-radius: 10px;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Segment = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#FFF")};
  overflow: hidden;

  padding: 80px 0;

  h2 {
    margin-top: 0;
  }
  h3 {
    font-size: 3.2rem;
    font-weight: 700;
  }
  p {
    font-size: 2.8rem;
    color: #226c91;
    font-weight: 500;
    margin-bottom: 60px;
  }
  ${media.tablet`
    h2, h3, p {
      text-align: center;
    }
  `}

  .mobile-button-group {
    text-align: center;
  }
`;

const Button = styled.button`
  border: 1px solid #d2d2d2;
  padding: 2px 12px 6px 12px;
  margin-right: 10px;
  border-radius: 10%;
  transition: all 0.5s;
  img {
    vertical-align: bottom;
    margin: 0;
  }
  &:hover {
    background-color: #eee;
  }
  ${media.tablet`
    margin-right: 0;
  `}
`;

const PageNum = styled.span`
  margin-left: 10px;
  color: #b7b7b7;
  font-size: 2rem;
  span {
    color: #226c91;
  }
  ${media.tablet`
    margin: 0 10px;
  `}
`;

function Review() {
  const [videoIndex, setVideoIndex] = useState(0);
  const videos = [
    { id: "Ko8_430snPE", comment: "내가 신경을 쓰지 않아도 알아서 되는 우리 집 공기질 관리예요", name: "4살 아이의 아빠, 이광형" },
    { id: "SWsPKcKRtu8", comment: "공기청정기만으로 안심할 순 없었어요", name: "아나운서 황보혜경" },
    { id: "RcM2_191ZWI", comment: "이산화탄소와 화학물질 수치가 내려가는 게 보여요", name: "애니팡 공동창업자 임현수" },
    { id: "HMT4hOf3Rdw", comment: "새까만 필터를 봤을 때 느껴지는 충격이 있어요", name: "팁스타운 센터장 안영일" },
  ];

  const youtubeOpts = {
    height: "390",
  };

  const youtubeOnReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <Segment bgColor={theme.colors.white}>
      <Container>
        <h2 className="tabletAndPhone">고객 후기</h2>
        <Grid gutter={60}>
          <GridItem col={50 / 93} media={{ tablet: 1, phone: 1 }}>
            <YouTubeContainer>
              <YouTube videoId={videos[videoIndex].id} opts={youtubeOpts} onReady={youtubeOnReady} />
            </YouTubeContainer>
          </GridItem>
          <GridItem col={43 / 93} media={{ tablet: 1, phone: 1 }}>
            <h2 className="onlyDesktop">고객 후기</h2>
            <h3>&ldquo;{videos[videoIndex].comment}&rdquo;</h3>
            <p>{videos[videoIndex].name}</p>
            <div className="onlyDesktop">
              <Button onClick={() => videoIndex > 0 && setVideoIndex(videoIndex - 1)} style={videoIndex === 0 ? { backgroundColor: "#EEE" } : {}}>
                <img src="/image/review/left.png" alt="left" />
              </Button>
              <Button
                onClick={() => videoIndex < videos.length - 1 && setVideoIndex(videoIndex + 1)}
                style={videoIndex === videos.length - 1 ? { backgroundColor: "#EEE" } : {}}
              >
                <img src="/image/review/right.png" alt="right" />
              </Button>
              <PageNum>
                <span>{videoIndex + 1}</span> / {videos.length}
              </PageNum>
            </div>
            <div className="tabletAndPhone mobile-button-group">
              <Button onClick={() => videoIndex > 0 && setVideoIndex(videoIndex - 1)} style={videoIndex === 0 ? { backgroundColor: "#EEE" } : {}}>
                <img src="/image/review/left.png" alt="left" />
              </Button>
              <PageNum>
                <span>{videoIndex + 1}</span> / {videos.length}
              </PageNum>
              <Button
                onClick={() => videoIndex < videos.length - 1 && setVideoIndex(videoIndex + 1)}
                style={videoIndex === videos.length - 1 ? { backgroundColor: "#EEE" } : {}}
              >
                <img src="/image/review/right.png" alt="right" />
              </Button>
            </div>
          </GridItem>
        </Grid>
      </Container>
    </Segment>
  );
}

export default Review;
