import React from "react";
import { InquiryWarp, InquiryBody, ModalBtn, StepTitle } from "./style";

function InquiryFormError({ setIsOpen }) {
  return (
    <InquiryWarp>
      <StepTitle>
        <h3>퍼핀플레이스 체험 신청에 실패하였습니다.</h3>
      </StepTitle>
      <InquiryBody>
        <ModalBtn onClick={() => setIsOpen(false)}>닫기</ModalBtn>
      </InquiryBody>
    </InquiryWarp>
  );
}

export default InquiryFormError;
