import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Header from "../components/header";
import Footer from "../components/footer";

import { Container } from "../styles/common";
import FilterStep2 from "../components/filter/step2";
import moment from "moment";

import { useParams } from "react-router-dom";

import GlobalStyle from "../styles/globalStyle";
import theme from "../styles/theme";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { GET_VISIT_REQUEST, GET_SCHEDULE_PROPOSAL, GET_SCHEDULE_LIST } from "../graphql/query";
import { UPDATE_VISIT_REQUEST, RESERVE_VISIT_REQUEST } from "../graphql/mutation";

function Reschedule() {
  const { requestId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentInviteeStartTime, setCurrentInviteeStartTime] = useState(); // 현재의 시작날짜
  const [inviteeStartTime, setInviteeStartTime] = useState(); // 선택된 시작날짜
  const [currentStep, setCurrentStep] = useState("2"); //  "2" | "SUCCESS" | "FAILED"
  const [locationId, setLocationId] = useState();

  // 1-1) visitRequest 조회
  const { data: visitRequestData } = useQuery(GET_VISIT_REQUEST, {
    variables: { visitRequestId: requestId },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setCurrentInviteeStartTime(moment(data.visitRequest?.inviteeStartTime).format("YYYY-MM-DD, a h:mm"));
      setInviteeStartTime(new Date(data.visitRequest?.inviteeStartTime));
      const locationId = data.visitRequest?.floor?.location?._id;
      if (!locationId) {
        setIsLoading(false);
        return setCurrentStep("FAILED");
      }
      setLocationId(locationId);
      getScheduleProposal({ variables: { locationId: locationId, floorId: data.visitRequest?.floor?._id } });
    },
    onError(error) {
      setCurrentStep("FAILED");
      console.error(error);
    },
  });

  // 1-2) 추천방문일자 조회
  const [getScheduleProposal, { data: proposalData }] = useLazyQuery(GET_SCHEDULE_PROPOSAL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      getScheduleList({ variables: { locationId: locationId } });
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  // 1-3) 전체방문가능일자 조회
  const [getScheduleList, { data: listData }] = useLazyQuery(GET_SCHEDULE_LIST, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setIsLoading(false);
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  //2-1) visitRequest 변경
  const [updateVisitRequest] = useMutation(UPDATE_VISIT_REQUEST, {
    onCompleted(data) {
      const visitRequestId = data.updateVisitRequest._id;
      reserveVisitRequest({
        variables: {
          visitRequestId: visitRequestId,
        },
      });
    },
    onError(error) {
      setIsLoading(false);
      console.error(error.message);
    },
  });

  //2-2) 예약
  const [reserveVisitRequest] = useMutation(RESERVE_VISIT_REQUEST, {
    onCompleted(data) {
      setCurrentInviteeStartTime(moment(data.reserveVisitRequest.inviteeStartTime).format("YYYY-MM-DD, a h:mm"));
      setIsLoading(false);
      setCurrentStep("SUCCESS");
    },
    onError(error) {
      setIsLoading(false);
      console.error(error.message);
    },
  });

  const submitInviteeData = (timeData) => {
    setIsLoading(true);
    updateVisitRequest({
      variables: {
        visitRequestId: requestId,
        data: {
          inviteeStartTime: timeData.inviteeStartTime,
          inviteeEndTime: timeData.inviteeEndTime,
        },
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      {isLoading && (
        <Loading>
          <img src="/image/loading.gif" alt="loading" />
        </Loading>
      )}
      <Container style={{ minHeight: "80vh" }}>
        <H2>
          {visitRequestData?.visitRequest?.floor?.location?.kaptInfo?.kaptName} {visitRequestData?.visitRequest?.floor?.name}
        </H2>
        {currentStep === "2" && proposalData && listData && (
          <>
            {currentInviteeStartTime && <H3>방문 예정 일시 : {currentInviteeStartTime}</H3>}
            <FilterStep2
              submitInviteeData={submitInviteeData}
              inviteeStartTime={inviteeStartTime}
              setInviteeStartTime={setInviteeStartTime}
              scheduleProposal={proposalData.getScheduleProposal}
              scheduleList={listData.getScheduleList}
              isModify={true}
            />
          </>
        )}
        {currentStep === "SUCCESS" && (
          <>
            <H2>방문 일정이 변경되었습니다</H2>
            <H3>방문 예정 일시 : {currentInviteeStartTime}</H3>
          </>
        )}
        {currentStep === "FAILED" && (
          <>
            <H2>접근에 실패하였습니다</H2>
          </>
        )}
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default Reschedule;

const Loading = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  img {
    opacity: 0.6;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const H2 = styled.h2`
  text-align: center;
  color: #404040;
`;

const H3 = styled.h3`
  text-align: center;
  color: #404040;
  font-size: 2.2rem;
`;
