import React, { useState } from "react";
import { InquiryWarp, InquiryBody, Form, ModalBtn, ErrorMsg, StepTitle } from "./style";

function InquiryForm3({ email, onChangeEmail, gotoNextPage, myTrim }) {
  const [errorMsg, setErrorMsg] = useState("");

  const onClickNextBtn = (e) => {
    e.preventDefault();
    setErrorMsg("");

    if (myTrim(email) === "") {
      setErrorMsg("입력되지 않은 값이 있습니다.");
      return;
    }

    if (!isEmail(email)) {
      setErrorMsg("유효한 이메일 형식이 아닙니다.");
      return;
    }

    gotoNextPage();
  };

  const isEmail = (email) => {
    const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regExp.test(email);
  };

  return (
    <InquiryWarp>
      <StepTitle>
        <h3>
          <span className="step">STEP 3</span> 공기 상태 리포트를 받을 이메일을 입력해주세요.
        </h3>
      </StepTitle>
      <InquiryBody>
        <Form>
          <input type="text" placeholder="이메일" value={email} onChange={onChangeEmail} />

          {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
          <ModalBtn onClick={(e) => onClickNextBtn(e)} disabled={false} id="inquiry3">
            다음
          </ModalBtn>
        </Form>
      </InquiryBody>
    </InquiryWarp>
  );
}

export default InquiryForm3;
