const breakpoints = {
  smallPhone: {
    max: 370,
  },
  phone: {
    min: 370,
    max: 768,
  },
  tablet: {
    min: 768,
    max: 992,
  },
  desktop: {
    min: 992,
    max: 1230,
  },
  reallyLarge: {
    min: 1230,
  },
};

const mainBlueColor = "#1baae6";
const darkBlueColor = "#116389";
const darkestBlueColor = "#394750";
const blue1Color = "#e1f4fb";
const blue2Color = "#d9eafa";
const grayColor = "#f6f6f6";
const darkGrayColor = "#e7e7e7";
const blackColor = "#222222";
const yellowColor = "#fbefe3";
const whiteColor = "#fff";

// 기존 컬러 (삭제 예정)
const primaryColor = "#fdf8f2";
const secondaryColor = "#5e899c";
const tertiaryColor = "#9bbed1";

const colors = {
  primary: primaryColor,
  secondary: secondaryColor,
  tertiary: tertiaryColor,
  mainBlue: mainBlueColor,
  darkBlue: darkBlueColor,
  darkestBlue: darkestBlueColor,
  blue1: blue1Color,
  blue2: blue2Color,
  gray: grayColor,
  darkGray: darkGrayColor,
  black: blackColor,
  yellow: yellowColor,
  white: whiteColor,
};

const theme = {
  breakpoints,
  colors,
};

export default theme;
