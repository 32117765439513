import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Header from "../../components/apartment/header";
import { useQuery } from "@apollo/react-hooks";
import { INIT_MAIN_PAGE } from "../../graphql/query";
import "../../styles/react-select-search.css";
import ApartmentLayout from "../../layout/apartmentLayout";

import SelectSearch from "react-select-search";

// const Theme = React.lazy(() => import("../../styles/apartment/theme"));

function Main() {
  const [aptItems, setAptItems] = useState([]);
  const [currnetLocationData, setCurrentLocationData] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);
  const history = useHistory();

  useQuery(INIT_MAIN_PAGE, {
    onCompleted(data) {
      const selectData = data.getLocationsIncludedInOrganization.map((cur) => ({
        value: `${cur.organizationId}__${cur.location._id}`,
        name: cur.location.kaptInfo.kaptName,
      }));
      setAptItems(selectData);
    },
    onError(error) {
      console.error(error);
    },
  });

  const onClickNext = () => {
    if (!currnetLocationData) {
      setIsNotFound(true);
      return;
    }
    const [orgId, locationId] = currnetLocationData.split("__");
    history.push(`/apartment/filter/org/${orgId}/location/${locationId}?home-btn=true`);
  };

  return (
    <ApartmentLayout>
      <div className="bg-main-blue">
        <Header />
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="mx-auto bg-white bg-opacity-20 py-10 px-6 md:px-20 rounded-md shadow-2xl">
            <p className="mb-5 text-center text-white text-xl md:text-2xl font-bold">거주하는 아파트를 검색해 주세요!</p>
            <div className="main-select-search">
              <SelectSearch
                options={aptItems}
                id="inquiry_address_2"
                search
                // disabled={aptItems.length === 0}
                onChange={(value) => {
                  setCurrentLocationData(value);
                }}
                placeholder="아파트 이름을 입력해 주세요"
              />
            </div>
            <button
              className={"mx-auto block bg-white py-1 px-12 rounded-md text-xl text-gray-600 focus:outline-none" + (!currnetLocationData && " bg-opacity-50")}
              onClick={onClickNext}
            >
              확인
            </button>
            {isNotFound && <p className="text-center mt-2 text-sm text-gray-200">현재 등록되어 있지 않습니다.</p>}
          </div>
          <Link to={"/apartment/new"} className="mt-5">
            <p className="text-center text-lg text-white underline opacity-80">찾으시는 아파트가 없으신가요 ?</p>
          </Link>
        </div>
      </div>
    </ApartmentLayout>
  );
}

export default Main;
