import React from "react";

function FilterStep1({ name, phone, email, dong, ho, onChange, submitUserData, errorMsg }) {
  return (
    <div>
      <h3 className="page-subtitle">필터 방문 교체</h3>
      <h2 className="page-title">주문 정보를 입력해 주세요.</h2>
      <form className="form" onSubmit={(e) => submitUserData(e)}>
        <div className="form-group">
          <label className="form-label" htmlFor="name">
            이름 <span className="form-star">*</span>
          </label>
          <input className="form-input" type="text" id="name" name="name" value={name} onChange={onChange} />
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone">
            휴대폰 <span className="form-star">*</span>
          </label>
          <input className="form-input" type="text" id="phone" name="phone" value={phone} onChange={onChange} maxLength={13} />
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="email">
            이메일 <span className="form-star">*</span>
          </label>
          <input className="form-input" type="text" id="email" name="email" value={email} onChange={onChange} />
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="dong">
            상세주소 <span className="form-star">*</span>
          </label>
          <div className="w-2/3">
            <input className="form-input w-2/6" type="text" id="dong" name="dong" value={dong} onChange={onChange} />
            <span className="w-1/6 mx-2 md:mr-6">동</span>
            <input className="form-input w-2/6" type="text" id="ho" name="ho" value={ho} onChange={onChange} />
            <span className="w-1/6 mx-2">호</span>
          </div>
        </div>
        <p className="text-main-blue my-10 text-xl text-center">{errorMsg ? errorMsg : <>&nbsp;</>}</p>
        <input className="bg-main-blue text-white px-14 py-2 rounded block mx-auto mt-30" type="submit" value="일정 선택" />
      </form>
    </div>
  );
}

export default FilterStep1;
