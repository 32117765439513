import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="fixed top-0 py-4 text-center shadow bg-white w-full z-10">
      <Link to={"/apartment"}>
        <img className="w-72 inline-block" src="/image/apartment/header/logo.svg" alt="PUFFIN PLACE" />
      </Link>
    </div>
  );
}

export default Header;
