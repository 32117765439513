import React from "react";
import { ThemeProvider } from "styled-components";
import { Container } from "../../styles/common";
import styled from "styled-components";
import media from "../../styles/media";

function Step0({ setCurrentStep, storeUrl, filterPrice, filterChangePrice }) {
  return (
    <Segment>
      <button
        onClick={() => {
          setCurrentStep("1");
        }}
      >
        방문 교체 서비스
        {!!filterChangePrice && <Price>{filterChangePrice.toLocaleString()}원</Price>}
      </button>
      <br />
      {storeUrl && (
        <a href={storeUrl} className="button" target="_blank" rel="noopener noreferrer">
          필터 배송 서비스
          {!!filterPrice && <Price>{filterPrice.toLocaleString()}원</Price>}
        </a>
      )}
      <br />
      <div className="links">
        <div className="link-item">
          <img src="/image/filter/kakao.png" alt="kakao" />
          <a href="https://pf.kakao.com/_Tygcxb" target="_blank" rel="noopener noreferrer">
            정기 관리 서비스 신청하기
          </a>
        </div>
        <div className="link-item">
          <img src="/image/filter/info.png" alt="info" />
          <a href="https://www.notion.so/puffinplanet/FAQ-2b81cdffb54247f99d8e1eff01ba9695" target="_blank" rel="noopener noreferrer">
            자주 묻는 질문
          </a>
        </div>
      </div>
    </Segment>
  );
}

export default Step0;

const Segment = styled.div`
  text-align: center;
  min-height: 400px;

  button,
  .button {
    display: inline-block;
    background-color: #1baae6;
    color: #fff;
    font-size: 3.6rem;
    font-weight: 500;
    padding: 10px 60px;
    border-radius: 30px;
    transition: all 0.5s;
    margin-bottom: 2.4rem;
    text-decoration: none;
    &:hover {
      background-color: darken(0.1, #1baae6);
    }
  }
  .links {
    width: 360px;
    margin: 2rem auto;
    padding: 0 1rem;
  }

  .link-item {
    text-align: left;
    margin-bottom: 2rem;
    img {
      vertical-align: middle;
      height: 40px;
    }
    a {
      margin-left: 10px;
      vertical-align: middle;
      display: inline;
      font-size: 2.4rem;
      text-align: left;
      text-decoration: none;
      font-size: 2.8rem;
      color: #404040;
      font-weight: 500;
    }
  }
  /* a {
    font-size: 2.4rem;
    text-decoration: none;
    color: #1baae6;
  } */
`;

const Price = styled.p`
  margin: 0px;
  margin-top: -4px;
  font-size: 2.4rem;
  color: #eee;
`;
