import React from "react";
import styled from "styled-components";

import { Grid, GridItem } from "styled-grid-responsive";
import media from "../../styles/media";

function FilterStep1({ name, phone, email, dong, ho, setName, setPhone, setEmail, setDong, setHo, submitUserData, errorMsg }) {
  const onChangePhone = (e) => {
    const strOriginal = e.target.value.replace(/[^0-9]/g, "");
    setPhone(strOriginal.replace(/(^01.{1})([0-9]+)([0-9]{4})/, "$1-$2-$3"));
  };

  return (
    <Segment>
      <form onSubmit={(e) => submitUserData(e)}>
        <Grid>
          <GridItem col={3 / 10} media={{ phone: 1 }}>
            <label htmlFor="name">이름</label>
          </GridItem>
          <GridItem col={7 / 10} media={{ phone: 1 }}>
            <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem col={3 / 10} media={{ phone: 1 }}>
            <label htmlFor="phone">휴대폰</label>
          </GridItem>
          <GridItem col={7 / 10} media={{ phone: 1 }}>
            <input type="text" id="phone" name="phone" value={phone} onChange={onChangePhone} maxLength={13} />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem col={3 / 10} media={{ phone: 1 }}>
            <label htmlFor="email">이메일</label>
          </GridItem>
          <GridItem col={7 / 10} media={{ phone: 1 }}>
            <input type="text" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem col={3 / 10} media={{ phone: 1 }}>
            <label htmlFor="dong">상세주소(동호수)</label>
          </GridItem>
          <GridItem col={7 / 10} media={{ phone: 1 }}>
            <Grid>
              <GridItem col={2 / 6}>
                <input type="text" id="dong" name="dong" value={dong} onChange={(e) => setDong(e.target.value)} />
              </GridItem>
              <GridItem col={1 / 6}>
                <p>동</p>
              </GridItem>
              <GridItem col={2 / 6}>
                <input type="text" id="ho" name="ho" value={ho} onChange={(e) => setHo(e.target.value)} />
              </GridItem>
              <GridItem col={1 / 6}>
                <p>호</p>
              </GridItem>
            </Grid>
            {/* <input type="text" id="dongho" name="dongho" value={dongho} onChange={(e) => setDongho(e.target.value)} /> */}
          </GridItem>
        </Grid>
        <ErrorMsgArea>{errorMsg && <p>{errorMsg}</p>}</ErrorMsgArea>
        <input type="submit" value="일정 선택" />
      </form>
    </Segment>
  );
}

export default FilterStep1;

const Segment = styled.div`
  form {
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
    width: 600px;
    margin: 0 auto;

    ${media.phone`
      width: 90%;
      padding: 20px;
    `}
  }

  textarea {
    height: 300px;
  }
  textarea,
  input[type="text"] {
    width: 100%;
    padding: 10px;
    line-height: 1.8em;
    border: 1px solid #e4e4e6;
    margin-bottom: 20px;
    font-weight: 300;
    border-radius: 10px;

    &:focus {
      outline: none !important;
      border: 1px solid #1baae6;
    }
  }
  label {
    position: relative;
    line-height: 2.8em;
    &:after {
      position: absolute;
      content: "*";
      top: -3px;
      right: -13px;
      color: #1baae6;
      font-size: 3rem;
    }
  }
  p {
    margin-top: 10px;
  }

  .normalMsg {
    color: #666;
    font-size: 0.8em;
  }

  input[type="submit"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #1baae6;
    font-size: 2rem;
    color: #fff;
    font-weight: 700;
    display: inline-block;
    border-radius: 30px;
    padding: 10px 60px;
    /* padding: 1.3rem 4rem; */
    /* border-radius: 4rem; */
    transition: all 0.5s;
    line-height: 3rem;
    width: 200px;
    display: block;
    margin: 0 auto;
    margin-top: 60px;
    &:hover {
      background-color: darken(0.1, #1baae6);
    }
    ${media.phone`
      font-size: 2.4rem;
    `}
  }
`;

const P = styled.div`
  color: #1baae6;
  font-size: 1.8rem;
  text-align: center;
`;

const ErrorMsgArea = styled.div`
  min-height: 40px;
  margin-top: 20px;
  p {
    color: #1baae6;
    font-size: 1.8rem;
    text-align: center;
  }
`;
