// 전자금융거래 기본약관
export const policy1 = `
제1조(목적) 
본 약관은 퍼핀플래닛 주식회사(이하 “회사”라 한다)가 제공하는 전자금융거래에 대한 기본적인 사항을 정함으로써 전자금융거래를 이용하고자 하는 고객과 회사 간의 권리․의무 관계를 명확하게 정함을 목적으로 한다.

제2조(정의) 
① 이 약관에서 사용하는 용어의 정의는 다음과 같다.
  1. “전자금융거래”란 회사가 전자적 장치를 통하여 금융상품 및 서비스를 제공하고 고객이 회사와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말한다.
  2. “고객”이란 전자금융거래를 위하여 회사와 체결한 계약(이하 “전자금융거래계약“이라 한다)에 따라 전자금융거래를 이용하는 자를 말한다.
  3. “전자지급거래”란 자금을 주는 자(이하 “지급인”이라 한다)가 회사로 하여금 전자지급수단을 이용하여 자금을 받는 자(이하 “수취인”이라 한다)에게 자금을 이동하게 하는 전자금융거래를 말한다.
  4. “전자적 장치”란 전자금융 거래정보를 전자적 방법으로 전송하거나 처리하는데 이용되는 장치로서 현금자동지급기, 자동입출금기, 지급용단말기, 컴퓨터, 전화기 그 밖에 전자적 방법으로 정보를 전송하거나 처리하는 장치를 말한다. 
  5. “전자문서”란 전자거래기본법 제2조제1호에 따른 작성, 송신․수신 또는 저장된 정보를 말한다.
  6. “접근매체”란 전자금융거래에 있어서 고객이 거래지시를 하거나 또는 고객 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 다음 각 목의 어느 하나에 해당하는 수단 또는 정보를 말한다.
   가. 전자식 카드 및 이에 준하는 전자적 정보
   나. 전자서명법 제2조 제4호의 전자서명생성정보 및 같은 조 제7호의 인증서
   다. 회사에 등록된 고객번호  
   라. 고객의 생체정보
   마. 가목 또는 나목의 수단이나 정보를 사용하는데 필요한 비밀번호 
  7. “전자지급수단”이란 전자자금이체, 직불전자지급수단, 선불전자지급수단, 전자화폐, 신용카드, 전자채권 그 밖의 전자적 방법에 따른 지급수단을 말한다.
  8. “전자자금이체”란 지급인과 수취인 사이에 자금을 지급할 목적으로 회사에 개설된 계좌에서 다른 계좌로 전자적 장치에 의하여 다음 각 목의 어느 하나에 해당하는 방법으로 자금을 이체하는 것을 말한다.
   가. 회사에 대한 지급인의 지급지시
   나. 회사에 대한 수취인의 추심지시(이하 “추심이체”라 한다)
  9. “거래지시”란 고객이 전자금융거래계약에 따라 회사에 전자금융거래의 처리를 지시하는 것을 말한다.
  10. “오류”란 고객의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 고객의 거래지시에 따라 이행되지 아니한 경우를 말한다.
  11. “개별약관”이란 이 약관과 함께 전자금융거래에 적용되는 약관으로서 회사가 별도로 작성한 약관을 말한다. (자동 결제 약관)
② 이 약관에서 별도로 정하지 아니한 용어의 정의는 「전자금융거래법」 및 「전자금융거래법 시행령」, 금융위원회의 전자금융감독규정 및 전자금융감독규정 시행세칙에서 정하는 바에 따른다. 

제3조(전자금융거래계약의 체결 및 해지) 
① 전자금융거래를 이용하고자 하는 고객은 개별약관에서 정하는 방법에 따라 회사와 전자금융거래계약을 체결하여야 한다.
② 회사는 제1항에 따른 전자금융거래계약을 체결함에 있어 고객에게 약관의 내용을 분명하게 밝혀야 하며, 고객의 요청이 있는 경우 다음 각 호의 어느 하나에 해당하는 방법으로 고객에게 약관을 내주어야 하여야 한다.
   1. 직접교부
   2. 전자문서의 전송(전자우편을 이용한 전송을 포함한다)
   3. 팩스(Fax)
   4. 우편
③ 회사는 제1항에 따른 전자금융거래계약을 체결함에 있어 고객이 약관의 내용에 대한 설명을 요청하는 경우 다음 각 호의 어느 하나에 해당하는 방법으로 고객에게 약관의 중요내용을 설명하여야 한다.
   1. 약관의 중요내용을 고객에게 직접 설명
 2. 약관의 중요내용에 대한 설명을 전자적 장치를 통하여 고객이 알기 쉽게 표시하고 고객으로부터 해당내용을 충분히 알았다는 의사표시를 전자적 장치를 통하여 받음
④ 고객은 개별약관에서 정하는 방법에 따라 전자금융거래계약을 해지할 수 있다.

제4조(이용시간 등) 
① 고객은 <별첨>에서 정하는 이용시간 이내에서 전자금융거래를 이용할 수 있다.
② 회사는 제1항에 따른 이용시간을 영업점과 전자적 장치에 게시하며, 이를 변경하고자 하는 경우 고객이 확인할 수 있도록 변경 예정일 ( )일/( )월 전부터 ( )일/( )월 이상 영업점에 마련해 두고 전자적 장치를 통해 게시한다.  다만, 시스템 장애복구, 프로그램의 긴급한 보수, 외부요인 등으로 인하여 이용시간이 변경된 경우에는 이를 미리 영업점에 마련해 두거나 전자적 장치를 통해 게시하지 아니할 수 있다.
③ 전산시스템 유지 및 보수로 인하여 전자금융거래 이용이 불가능할 경우 회사는 해당사실을 ( )일 전에 영업점과 전자적 장치에 게시한다. 

제5조(수수료) 
① 회사는 전자금융거래와 관련하여 <별첨>에서 정하는 각종 수수료를 고객으로부터 받을 수 있다.
② 회사는 제1항에 따른 수수료의 기준을 고객에게 안내한다.

제4조(거래내용의 확인) 
① 회사는 고객이 전자금융거래에 사용하는 전자적 장치(회사와 고객 사이에 미리 약정한 전자적 장치가 있는 경우에는 그 전자적 장치를 포함한다)를 통하여 거래내용을 확인할 수 있도록 한다. 이 경우 전자적 장치의 운영 장애, 그 밖의 사유로 거래내용을 확인하게 할 수 없는 때에는 회사는 인터넷 등을 이용하여 즉시 그 사유를 알리고, 그 사유가 종료된 때부터 고객이 거래내용을 확인할 수 있도록 한다.
② 고객은 거래지시와 제1항에 따른 거래내용이 일치하는지 여부를 확인하여야 한다. 
③ 고객은 거래내용을 서면(전자문서를 제외한다. 이하 이 조에서 같다)으로 제공하여 줄 것을 회사에 요청할 수 있으며, 회사는 그 요청을 받은 날로부터 2주 이내에 거래내용에 관한 서면을 내준다. 이 경우 거래내용의 서면 제공과 관련한 요청방법․절차, 접수창구의 주소(전자우편주소를 포함한다) 및 전화번호 등은 개별약관에서 정한 바에 따른다. 
④ 회사는 제3항에 따라 고객으로부터 거래내용을 서면으로 제공할 것을 요청받은 경우 전자적 장치의 운영 장애, 그 밖의 사유로 거래내용을 제공할 수 없는 때에는 그 고객에게 즉시 이를 알린다. 이 경우 거래내용에 관한 서면의 교부기간을 산정함에 있어서 전자적 장치의 운영 장애, 그 밖의 사유로 거래내용을 제공할 수 없는 기간은 이를 포함하지아니한다.
⑤ 회사가 제1항 및 제3항에 따라 제공하는 거래내용의 종류(조회거래는 제외한다. 이하 이 조에서 같다.), 범위 및 대상기간은 다음 각 호와 같다.
   1. 전자금융거래가 계좌를 통하여 이루어지는 경우 거래계좌의 명칭 또는 번호 : 5년
   2. 전자금융거래의 종류 및 금액, 전자금융거래의 상대방에 관한 정보 : 5년
   3. 전자금융거래의 거래일시, 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보 : 5년
   4. 회사가 전자금융거래의 대가로 받은 수수료 : 5년
   5. 추심이체 시 지급인의 출금동의에 관한 사항 : 5년
   6. 오류정정 요구사실 및 처리결과에 관한 사항 : 1년
   7. 전자금융거래 신청, 조건변경에 관한 내용 : 5년
 
제5조(오류의 정정) 
① 고객은 전자금융거래에 오류가 있음을 안 때에는 회사에게 이에 대한 정정을 요구할 수 있다.
② 회사는 제1항에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리하며 정정요구를 받은 날부터 2주 이내에 오류의 원인과 처리 결과를 문서, 전화 또는 전자우편으로 고객에게 알려야 한다. 다만 고객이 문서로 알려줄 것을 요청하는 경우에는 문서로 알려야 한다. 
③ 회사가 전자금융거래에 오류가 있음을 안 때에는 이를 즉시 조사하여 처리하며, 오류가 있음을 안 날로부터 2주 이내에 오류의 원인과 처리 결과를 문서, 전화 또는 전자우편으로 고객에게 알려야 한다. 다만 고객이 문서로 알려줄 것을 요청하는 경우에는 문서로 알려야 한다.

제6조(회사의 책임) 
① 회사는 다음 각 호의 어느 하나에 해당하는 사고로 인하여 고객에게 손해가 발생한 경우에는 그 손해를 배상할 책임을 진다. 
  1. 접근매체의 위조나 변조로 발생한 사고
  2. 계약체결 또는 거래지시의 전자적 전송이나 처리 과정에서 발생한 사고
   3. 전자금융거래를 위한 전자적 장치 또는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제2조제1항제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고
② 제1항에 불구하고 다음 각 호의 어느 하나에 해당하는 사유로 인하여 고객에게 발생한 손해에 대하여는 고객이 그 책임의 전부 또는 일부를 부담하게 할 수 있다. 
   1. 고객이 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공한 경우
   2. 제3자가 권한 없이 고객의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고 고객이 자신의 접근매체를 누설 또는 노출하거나 방치한 경우
   3. 법인(중소기업기본법 제2조제2항에 따른 소기업을 제외한다)인 고객에게 손해가 발생한 경우로 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 주의의무를 다한 경우
   4. 회사가 전자금융거래법 제6조제1항에 따른 확인 외에 보안강화를 위하여 전자금융거래 시 요구하는 추가적인 보안조치를 고객이 정당한 사유 없이 거부하여 전자금융거래법 제9조제1항제3호의 사고가 발생한 경우
   5. 고객이 제4호에 따른 추가적인 보안조치에 사용되는 매체ㆍ수단 또는 정보에 대하여 다음 각 목의 어느 하나에 해당하는 행위를 하여 전자금융거래법 제9조제1항제3호에 따른 사고가 발생한 경우
     가. 누설ㆍ노출 또는 방치한 행위
  나. 제3자에게 대여하거나 그 사용을 위임한 행위 또는 양도나 담보의 목적으로 제공한 행위
③ 회사는 고객으로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용하여 고객에게 발생한 손해를 배상한다.
④ 회사는 제1항부터 제3항에 불구하고 다른 법령에 고객에게 유리하게 적용될 수 있는 규정이 있는 경우 그 법령을 우선 적용한다.

제7조(전자금융거래 장애시의 처리) 
① 회사는 천재지변, 정전, 화재, 건물훼손, 전산장애 등의 사유로 고객의 거래지시를 처리할 수 없거나 처리가 지연될 경우 해당 사실과 사유, 대체주문방법 등을 고객에게 지체 없이 통보하며, 정상적인 전자금융거래가 이루어질 수 있도록 신속하게 조치한다.
② 제1항에 따른 대체주문방법은 개별약관에서 정한 바에 따른다. 

제8조(전자지급거래의 효력발생시기 등) 
① 전자지급수단을 이용하여 자금을 지급하는 경우 지급의 효력은 다음 각 호에서 정한 때에 발생한다.
   1. 전자자금이체의 경우 : 거래지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관 계좌의 원장에 입금기록이 끝난 때
       2. 전자적장치로부터 직접 현금을 출금하는 경우 : 수취인이 현금을 받은 때
   3. 선불전자지급수단 및 전자화폐로 지급하는 경우 : 거래지시된 금액의 정보가 수취인이 지정한 전자적 장치에 도달한 때
   4. 그 밖의 전자지급수단으로 지급하는 경우 : 거래지시 된 금액의 정보가 수취인의 계좌가 개설되어 있는 금융기관의 전자적 장치에 입력이 끝난 때
② 고객은 거래지시를 하는 때부터 회사가 안내한 시간 후에 전자자금이체 지급 효력이 발생(이하 “지연이체”라 한다)하도록 할 수 있다.
③ 지연이체를 원하는 고객은 컴퓨터, 전화기, 그 밖의 전자적 장치를 이용하여 제2항의 지연이체 거래지시를 할 수 있다.
④ 고객은 제1항 각 호에 따라 지급의 효력이 발생하기 전까지 거래지시를 철회할 수 있다.
⑤ 제4항에 불구하고 회사는 대량으로 처리하는 거래 또는 예약에 따른 거래 등의 경우에는 개별약관에서 정한 바에 따라 거래지시의 철회시기를 달리 정할 수 있다.
⑥ 제4항 및 제5항에 따른 거래지시의 철회방법과 절차는 개별약관에서 정하는 바에 따른다. 

제9조(전자지급거래의 지연 인출 및 이체) 
고객이 현금자동지급기, 자동입출금기 등 전자적 장치에서 카드, 통장 등의 접근매체를 이용하여 고객 본인 계좌의 현금을 인출 및 이체 하고자 할 때, 인출 및 이체 전 회사가 고객에게 안내한 금액 이상의 현금이 송금·이체되어 고객 본인 계좌로 입금된 경우 회사가 고객에게 안내한 시간 동안 입금된 금액의 범위 내에서 인출 및 이체가 지연될 수 있다.

제10조(인출한도 제한) 
고객이 보유한 계좌가 1년 이상 장기미사용 계좌에 해당하는 경우 자동화기기(ATM/CD)를 이용한 1일 인출한도는 회사가 고객에게 안내한 금액 이하로 제한된다. 

제11조(추심이체의 출금 동의 등) 
① 회사는 추심이체를 실행하기 위하여 다음 각 호의 어느 하나에 해당하는 방법으로 미리 지급인으로부터 출금에 대한 동의를 얻어야 한다.
   1. 회사가 지급인으로부터 서면(공인전자서명이 있는 전자문서를 포함한다) 또는 녹취(전화 녹취, 음성응답 시스템(Audio Response System ; ARS)을 포함한다)의 방법으로 출금 동의를 받는 방법
   2. 수취인이 지급인으로부터 제1호의 방법으로 출금 동의를 받아 회사에게 전달(전자적인 방법에 의하여 출금동의의 내역을 전송하는 것을 포함한다)하는 방법
② 지급인은 수취인의 거래지시에 따라 지급인의 계좌의 원장에 출금기록이 끝나기 전까지 회사에게 제1항에 따른 동의의 철회를 요청할 수 있다.
③ 제2항에 불구하고 회사는 대량으로 처리하는 거래 또는 예약에 따른 거래 등의 경우에는 개별약관에서 정한 바에 따라 동의의 철회시기를 달리 정할 수 있다. 
④ 제2항 및 제3항에 따른 동의의 철회방법과 절차는 개별약관에서 정하는 바에 따른다.

제12조(접근매체의 사용 등) 
① 회사는 전자금융거래의 종류·성격·위험수준 등을 고려하여 안전한 인증방법을 사용하여야 한다.
② 고객은 접근매체를 사용 및 관리함에 있어서 다른 법률에 특별한 규정이 없는 한 다음 각 호의 행위를 하여서는 아니 된다. 
   1. 접근매체를 양도하거나 양수하는 행위
    2. 대가를 수수(授受)․요구 또는 약속하면서 접근매체를 대여받거나 대여하는 행위 또는 보관․전달․유통하는 행위
   3. 범죄에 이용할 목적으로 또는 범죄에 이용될 것을 알면서 접근매체를 대여받거나 대여하는 행위 또는 보관․전달․유통하는 행위
   4. 접근매체를 질권(채무자가 돈을 갚을 때까지 채권자가 담보물을 보유할 수 있고, 채무자가 돈을 갚지 않을 때는 그 담보물을 사용 또는 처분하여 우선적으로 빌려준 돈을 받을 수 있는 권리)의 목적으로 하는 행위
   5. 제1호부터 제4호까지의 행위를 알선하는 행위
③ 고객은 접근매체를 제3자에게 누설하여서는 아니 되며, 접근매체의 분실, 도용, 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 한다.

제13조(신고사항의 변경 및 사고신고) 
① 고객이 주소(전자우편주소를 포함한다), 전화번호, 각종 비밀번호 등 회사에 신고한 사항을 변경하고자 하는 경우 개별약관에서 정하는 방법에 따라 회사에 신고하여야 한다.
② 고객은 접근매체 등 전자금융거래에 있어 비밀을 요하는 사항이 도난, 분실, 위조, 변조 또는 누설되었음을 알았을 때에는 지체 없이 이를 개별약관에서 정한 방법에 따라 회사에 신고하여야 한다.

제14조(약관의 변경 등) 
① 회사는 약관을 변경하고자 하는 경우 시행일 1개월 전에 변경되는 약관을 전자금융거래를 수행하는 전자적 장치(해당 전자적 장치에 게시하기 어려운 경우에는 고객이 접근하기 쉬운 전자적 장치로서 회사가 지정하는 대체장치를 포함한다. 이하 이 조에서 같다)에 게시하고 고객에게 전자문서 등 고객과 사전에 합의된 방법으로 통지하여야 한다. 다만, 고객이 이의를 제기할 경우 회사는 고객에게 적절한 방법으로 약관 변경내용을 통지하였음을 확인해 주어야 한다.  
② 제1항에 불구하고 회사가 법령의 개정으로 인하여 긴급하게 약관을 변경하는 때에는 변경된 약관을 전자적 장치에 1개월 이상 게시하고 제1항에서 정한 방법으로 고객에게 통지하여야 한다. 
③ 고객은 제1항 및 제2항에 따라 약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의 영업일까지 전자금융거래계약을 해지할 수 있다. 
④ 회사는 제3항에 따른 기간 동안 고객이 약관의 변경내용에 대하여 이의를 제기하지 아니하는 경우에는 약관의 변경을 승인한 것으로 본다. 
⑤ 회사는 고객이 약관의 교부를 요청하는 경우 이에 응하여야 하며, 전자금융거래를 수행하는 전자적 장치에 게시하여 고객이 약관을 조회하고 다운로드(화면출력을 포함한다)받을 수 있도록 한다.

제15조(고객정보에 대한 비밀보장) 
회사는 관계법령에서 정한 경우를 제외하고는 전자금융거래를 수행함에 있어 알게 된 다음 각 호의 어느 하나에 해당하는 고객정보를 고객의 동의를 얻지 아니하고 타인에게 제공하거나 업무상 목적 외에 사용하지 아니한다.
   1. 고객의 인적사항
   2. 고객의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료

제16조(분쟁처리 및 분쟁조정) 
① 고객은 전자금융거래의 처리에 관하여 이의가 있을 때에는 회사에 손해배상 등 분쟁처리를 요구하거나 금융감독원의 금융분쟁조정위원회 또는 한국소비자원의 소비자분쟁조정위원회, 한국금융투자협회, 한국거래소 등을 통하여 분쟁조정을 신청할 수 있다.
② 회사는 손해배상 등 분쟁처리를 위한 분쟁처리책임자 및 담당자를 지정하며, 그 연락처(전화번호․모사전송번호․전자우편주소 등을 말한다)를 인터넷 등을 통하여 고객에게 알린다.
③ 제1항에 따라 고객이 회사에 손해배상 등 분쟁처리를 신청한 경우 회사는 15일 이내에 이에 대한 조사 또는 처리결과를 고객에게 통보한다. 
 
제17조(약관적용의 우선순위 등) 
① 전자금융거래에 관해서는 이 약관을 우선 적용하며, 이 약관에서 정하지 않은 사항은 개별약관 및 전자금융거래법 등 관계법령이 정하는 바에 따른다.
② 회사와 고객 간에 개별적으로 합의한 사항이 이 약관에서 정한 사항과 다를 때에는 그 합의사항을 이 약관에 우선하여 적용한다.

제18조(조사 협조 등) 
고객은 제8조제1항에 따른 사고로 인한 손해배상 등 분쟁처리와 관련한 회사의 사고조사와 관계당국의 수사 또는 조사절차에 협조하여야 한다.

제19조(준거법 등) 
① 약관의 해석 및 적용에 관하여는 대한민국법을 적용한다.
② 이 약관에 의한 거래와 관련하여 발생된 분쟁에 대하여 회사와 고객 사이에 소송의 필요가 생긴 경우에는 그 관할법원은「민사소송법」이 정한 바에 따른다.
`;

// 개인정보 처리방침
export const policy2 = `
퍼핀플래닛 주식회사(이하 “회사")는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다.

제1조(개인정보의 처리목적)
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. 

서비스 가입/변경/해지 처리, 기술지원 문의 등을 포함한 이용계약 관련 사항의 처리
퍼핀플래닛 인공지능 환기 서비스 제공
회사가 제공하게 되는 신상품의 소개, 이벤트 안내, 고객 유치, 관리 등을 포함한 각종 마케팅

제2조(수집하는 개인정보의 항목 및 수집방법)
회사는 상담, 서비스 제공을 위하여 아래와 같이 필요한 최소한의 개인정보만을 수집하며, 개인의 사상, 출산지, 건강상태 등 고객의 인권을 침해할 우려가 있는 개인정보는 수집하지 않습니다. 다만 고객께서 수집에 동의하시는 경우는 예외로 합니다.
2.1 수집하는 개인정보의 항목
신청인의 성명, 이메일 주소, 연락처
요금납부자의 카드정보 등 서비스 제공에 필수적인 사항
2.2 회사는 다음과 같은 방법으로 개인정보를 수집합니다. 
서비스 신청서, 인터넷 홈페이지, 전화 등을 통한 상담을 위하여 고객이 알려준 개인정보 수집
서비스 이용 또는 업무처리 과정에서 생성되는 정보 수집

제3조(개인정보의 처리 및 보유기간)
회사는 법령에 따른 개인정보 보유,이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다. 

3.1 고객 관리 : 서비스 해지시까지
다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
관계 법령 위반에 따른 수사,조사 등이 진행 중인 경우에는 해당 수사,조사 종료 시까지
홈페이지 이용에 따른 채권,채무관계 잔존 시에는 해당 채권,채무관계 정산 시까지
3.2 서비스 제공 : 서비스 공급완료 및 요금 결제, 정산 완료 시까지
다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지
“전자상거래 등에서의 소비자 보호에 관한 법률"에 따른 표시,광고,계약내용 및 이행 등 거래에 관한 기록
표시,광고에 관한 기록 : 6개월
계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년
소비자 불만 또는 분쟁처리에 관한 기록 : 3년
“통신비밀보호법" 제41조에 따른 통신사실확인자료 보관
가입자 전기통신일시, 개시 종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년
컴퓨터통신, 인터넷 로그기록자료, 좁속지 추적자료 : 3개원
“정보통신망 이용촉진 및 정보보호 등에 관한 법률"시행령 제29조에 따른 본인확인정보 보관 : 게시판에 정보 게시가 종료된 후 6개월

제4조(개인정보의 제3자 제공)
회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. 정보주체는 본 동의를 거부할 권리가 있으나, 동의 거부 시 서비스 제공이 어려울 수 있습니다.

제5조(정보주체와 법정대리인의 권리,의무 및 행사방법)
5.1 정보주체는 회사에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.
5.2 제5.1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면, 전자우편, FAX 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
5.3 제5.1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. 
5.4 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다. 
5.5 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. 
5.6 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다. 

제6조(개인정보 파기)
회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 
정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 
개인정보 파기의 절차 및 방법은 다음과 같습니다. 
   1. 파기절차 
   회사는 파기 사유가 발생한 개인정보를 선정하고, <사업자/단체명>의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. 
   2. 파기방법 
    회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록,저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. 

제7조(개인정보의 안전성 확보조치) 
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. 
   1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등 
   2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 
   3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제 


제8조(개인정보 보호책임자)
회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 

   ▶ 개인정보 보호책임자 
       성명 : 선강민
       직책 : 대표 
       연락처 : 02-508-5225, seon@puffinplace.com 

정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 
회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다. 

제9조(개인정보 열람청구) 
정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 
회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다. 

   ▶ 개인정보 열람청구 접수․처리 부서 
       부서명 : 개발팀
       담당자 : 선강민
       연락처 : 02-508-5225, seon@puffinplace.com

제10조(개인정보 처리방침 변경) 
이 개인정보 처리방침은 2020. 1. 15부터 적용됩니다

`;

// 마케팅 정보 활용 동의 (선택)
export const marketingPolicy = `
마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집 이용을 거부하실 수 있으며, 
거부 시에도 퍼핀플레이스 서비스를 이용하실 수 있으나 동의를 거부한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다.

- 개인정보 수집 항목 : (웹사이트 이용 또는 이용 과정 시 수집 정보) 이름, 휴대폰번호, 이메일, 주소
- 개인정보 수집 이용 목적 : 혜택 정보, 각종 이벤트 정보, 상품 정보, 신규 서비스 안내 등 광고성 정보 제공
- 보유 및 이용기간 : 마케팅 활용 동의일로부터 2년, 이용자가 동의 철회 요청 시 즉시
`;
