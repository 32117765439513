import React from "react";
import Routes from "./routes";

import { ApolloProvider } from "@apollo/react-hooks";
import client from "./apollo";

const App = () => (
  <ApolloProvider client={client}>
    <Routes />
  </ApolloProvider>
);

export default App;
