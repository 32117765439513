import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Container } from "../styles/common";
import { Grid, GridItem } from "styled-grid-responsive";
import theme from "../styles/theme";
import media from "../styles/media";
import { darken } from "polished";
import { Waypoint } from "react-waypoint";

const textAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Segment = styled.div`
  padding: 80px 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#FFF")};
  overflow: hidden;

  overflow-anchor: none;
  padding: 60px 0;
  h2 {
    text-align: center;
  }
  .item {
    width: 100%;
    cursor: pointer;
    position: relative;
    margin-right: 0;

    img {
      width: 100%;
    }
    .title {
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      border: 2px solid white;
      border-radius: 100%;

      padding-left: 5px;
      padding-right: 5px;

      display: inline-block;
      text-align: center;
      transition: all 0.5s ease;
      line-height: 1.4em;

      opacity: 0;
      &.add-anim {
        animation: ${textAnim} 0.5s ease-in forwards;
        animation-delay: 0.25s;
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 106px;

        color: white;
        font-size: 2.6rem;
        margin: 0;
        line-height: 1.4;

        ${media.tablet`
          font-size: 2rem;
        `}

        span {
          font-size: 3rem;
          font-weight: 700;
          display: block;

          ${media.tablet`
            font-size: 2.2rem;
          `}
        }
      }
    }

    .desc {
      opacity: 0;
      position: absolute;
      font-weight: 500;
      left: 20px;
      bottom: 20px;
      transition: all 0.5s ease;

      p {
        color: white;
        margin: 0;
      }
      ${media.phone`
        left: 10px;
        bottom: 10px;
        p{
          font-size: 2.2rem;
        }
      `}
    }

    &:hover .title,
    &:hover .title.add-anim {
      opacity: 0 !important;
      transition: all 1s ease;
    }
    &:hover .desc,
    &:hover .desc.add-anim {
      opacity: 1 !important;
      transition: all 1s ease;
    }
  }
`;

const More = styled.button`
  margin-top: 6px;
  font-size: 2.2rem;
  text-align: center;
  width: 100%;
  background-color: #e7e7e7;
  padding: 8px 0;
  border-radius: 2px;
  transition: all 0.5s;
  &:hover {
    background-color: ${(props) => darken(0.1, "#e7e7e7")};
  }
`;

const addAnim = () => {
  const divs = document.querySelectorAll(`.place__anim-area`);
  for (let i = 0; i < divs.length; i++) {
    divs[i].classList.add("add-anim");
  }
};

function Place() {
  const pageSize = 8;
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page === 1) return;
    addAnim();
  }, [page]);

  const datas = [
    { title: ["서울", "강남구"], items: ["강남 한양수자인", "래미안 대치팰리스", "청담동 빌폴라리스", "청담동 효성빌라"] },
    { title: ["서울", "서초구"], items: ["대림아크로리버파크", "반포 래미안 아이파크", "반포 푸르지오 써밋", "삼성서초 트라팰리스"] },
    { title: ["서울", "송파구"], items: ["송파 헬리오시티", "송파위례24단지꿈에그린", "잠실 푸르지오 월드마크"] },
    { title: ["서울", "용산·중구"], items: ["용산 파크타워", "한남 나인원", "청구e편한세상아파트"] },
    { title: ["경기", "성남시"], items: ["알파돔시티 판교알파리움", "운중동 푸르지오 하임", "판교원마을 휴먼시아 힐스테이트", "판교호반써밋플레이스"] },
    { title: ["경기", "하남시"], items: ["미사강변 리버스위트칸타빌", "위례 신안인스빌 아스트로", "하남 감일 스윗시티"] },
    { title: ["경기", "남양주시"], items: ["별내 쌍용예가아파트", "신안인스빌 퍼스트포레"] },
    { title: ["경기", "화성시"], items: ["대원칸타빌 포레지움", "동탄 꿈에그린프레스티지"] },
    {
      title: ["서울", "광진·동대문·\n성동·중랑구"],
      items: [
        "강변 SK뷰",
        "워커힐 푸르지오",
        "답십리 래미안 위브",
        "강변 건영아파트",
        "서울숲 리버뷰 자이",
        "서울숲 트리마제",
        "송정동 서울숲 아이파크",
        "상봉 베스트원",
        "신금호 파크 자이",
      ],
    },
    { title: ["서울", "강동구"], items: ["강동역 신동아 파밀리에", "고덕 그라시움", "고덕 래미안 힐스테이트"] },
    { title: ["서울", "마포·서대문구"], items: ["상암월드컵파크 9단지", "DMC 에코자이"] },
    { title: ["서울", "관악구"], items: ["e편한세상 서울대입구"] },
    { title: ["경기", "파주시"], items: ["파주 한양수자인 리버팰리스"] },
    { title: ["인천", "광역시"], items: ["구월 아시아드 선수촌 아파트"] },
  ];

  const onClickMoreBtn = () => {
    setPage(page + 1);
  };

  return (
    <Segment bgColor={theme.colors.gray}>
      <Container>
        <h2>서비스 도입 지역</h2>
        <Waypoint onEnter={addAnim}>
          <Grid gutter={10}>
            {datas.map((data, index) => {
              if (index + 1 > pageSize * page) return null;
              return (
                <GridItem col={1 / 4} media={{ phone: 1 / 2 }}>
                  <div className="item">
                    <div className="title place__anim-area">
                      <p>
                        {data.title[0]}
                        <span style={data.title[1].length > 6 ? { fontSize: "1.8rem" } : {}}>
                          {data.title[1].indexOf("\n") !== -1
                            ? data.title[1].split("\n").map((cur) => (
                                <span style={{ fontSize: "1.8rem" }}>
                                  {cur}
                                  <br />
                                </span>
                              ))
                            : data.title[1]}
                        </span>
                      </p>
                    </div>
                    <div className="desc">
                      {data.items.map((item) => (
                        <p>{item}</p>
                      ))}
                    </div>
                    <img src={`/image/place/${index + 1}.png`} alt={index + 1} />
                  </div>
                </GridItem>
              );
            })}
          </Grid>
        </Waypoint>
        <More onClick={onClickMoreBtn} style={datas.length <= pageSize * page ? { display: "none" } : {}}>
          더보기+
        </More>
      </Container>
    </Segment>
  );
}

export default Place;
