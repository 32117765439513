import React, { useState } from "react";

function PpModal({ isOpenPpModal, setIsOpenPpModal }) {
  return (
    <div className={(isOpenPpModal ? "flex" : "hidden") + " absolute top-0 left-0 w-full h-full bg-black z-20 bg-opacity-40 flex justify-center items-center"}>
      <div className="relative bg-white md:w-2/5 md:h-3/5 rounded p-10">
        <div className="absolute top-4 right-4" onClick={() => setIsOpenPpModal(false)}>
          <img className="w-4" src="/image/apartment/common/close_btn.png" alt="close btn" />
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">
            <div className="w-8 h-1 bg-main-blue mb-2 md:mt-20" />
            <h2 className="text-main-blue text-2xl font-bold text-left">정기 관리 서비스</h2>
            <p className="text-gray-500 mt-6">
              우리 집 환기시스템 필터
              <br />
              언제, 어떻게 교체해야 할지 막막하셨나요?
              <br />
              <br />
              가장 정확한 <b>교체 타이밍을 체크</b>하고
              <br />
              번거로운 <b>교체까지 알아서</b> 해결해 드릴게요.
            </p>
          </div>
          <div className="flex-1 relative">
            <div className="flex flex-row mt-6  lg:absolute lg:bottom-0 w-full">
              <div className="flex-1 bg-main-blue text-white flex justify-center items-center p-2 m-1 leading-tight">
                공기질
                <br />
                측정
              </div>
              <div className="flex-1 bg-main-blue text-white flex justify-center items-center p-2 m-1 leading-tight">ai 환기</div>
              <div className="flex-1 bg-main-blue text-white flex justify-center items-center p-2 m-1 leading-tight">
                필터
                <br />
                방문 교체
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-6 lg:mt-20">
          <a
            className="bg-kakao-yellow text-kakao-brown text-lg py-2 px-3 inline-block text-center rounded-3xl"
            href="https://pf.kakao.com/_Tygcxb"
            target="_blank"
            rel="noopener noreferrer"
          >
            카카오톡으로 문의하기 <img className="w-8 inline-block" src="/image/apartment/common/kakao.png" alt="kakao" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default PpModal;
