import React from "react";
import styled, { keyframes } from "styled-components";
import { Container } from "../styles/common";
import { darken } from "polished";
import theme from "../styles/theme";
import media from "../styles/media";

const bgColorAnim = keyframes`
0% {
  background-color: #fbefe3;
}
50% {
  background-color: #fbefe3;
}
100% {
  background-color: #e1f4fc;
}`;

const Segment = styled.div`
  background-color: #fbefe3;
  min-height: 736px;
  z-index: 1;
  animation: ${bgColorAnim} 2s ease-in forwards;
  animation-delay: 4s;
  ${media.tablet`
    min-height: auto;
    padding: 0 10px 60px 10px ;
  `}
  ${media.phone`
    padding: 0 0px 60px 0px ;
  `}
`;

const LeftArea = styled.div`
  padding-top: 150px;
  ${media.tablet`
    padding-top: 0px;
    margin-top: -38px;
    text-align: center;
  `}
`;

const RightArea = styled.div`
  z-index: 1;
  object {
    position: absolute;
    right: 10px;
    top: 0;
    width: 803px;
  }

  @media (max-width: 1515px) {
    object {
      width: 685px;
      top: 50px;
    }
  }

  ${media.tablet`
    position: relative;
    object {
      width: 100%;
      position:relative;
      margin-top: -7%;
      top: 0px;
    }  
  `}
`;

const H2 = styled.h2`
  font-size: 4.4rem;
  margin-bottom: 6px;
  ${media.tablet`
    font-size :3.8rem;
  `}

  ${media.phone`
    margin-top: 0px;
  `}
`;

const P = styled.p`
  font-size: 2.6rem;
  font-weight: 500;
  ${media.tablet`
    font-size :2.2rem;
  `}
`;

const Btn = styled.button`
  background-color: ${(props) => props.bgColor};
  font-size: 2.6rem;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  padding: 1.8rem 4rem;
  border-radius: 4rem;
  transition: all 0.5s;
  margin-top: 10px;
  &:hover {
    background-color: ${(props) => darken(0.1, props.bgColor)};
  }
`;

function Cover({ handleContact }) {
  return (
    <Segment>
      <Container style={{ position: "relative" }}>
        <RightArea>
          <div className="desktop">
            <object type="image/svg+xml" data="/image/header/cover.svg" aria-labelledby="cover"></object>
          </div>
          <div className="phone">
            <object type="image/svg+xml" data="/image/header/cover_m.svg" aria-labelledby="cover mobile"></object>
          </div>
        </RightArea>
        <LeftArea>
          <H2 className="phone">
            공기는 계속 좋아야 한다.
            <br />
            온종일 신경쓰지 않아도,{" "}
            <span style={{ color: theme.colors.darkBlue }}>
              <br />
              퍼핀플레이스
            </span>
          </H2>

          <H2 className="desktop">
            공기는 계속 좋아야 한다.
            <br />
            온종일 신경쓰지 않아도, <span style={{ color: theme.colors.darkBlue }}>퍼핀플레이스</span>
          </H2>
          <P>
            가정 내 환기장치를 활용한 <span style={{ color: theme.colors.darkBlue }}>AI 공기정화 서비스</span>를 제공합니다.
          </P>
          <Btn bgColor={theme.colors.mainBlue} onClick={() => handleContact(true)}>
            퍼핀플레이스 체험 신청
          </Btn>
        </LeftArea>
      </Container>
    </Segment>
  );
}

export default Cover;
