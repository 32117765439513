import React from "react";
import { ThemeProvider } from "styled-components";
import { Container } from "../../styles/common";
import styled from "styled-components";

function FilterStepFiled() {
  return (
    <Container style={{ position: "relative", paddingTop: "80px", paddingBottom: "80px", minHeight: "400px" }}>
      <H3> 유효하지않은 접근입니다.</H3>
    </Container>
  );
}

export default FilterStepFiled;

const H3 = styled.h3`
  font-size: 2.6rem;
  text-align: center;
  margin-bottom: 1rem;
`;
