import React, { useState } from "react";
import { InquiryWarp, InquiryBody, Form, ModalBtn, ErrorMsg, StepTitle } from "./style";

function InquiryForm2({ answerKey2, onChangeRadioBtn, name, onChangeName, phone, setPhone, gotoNextPage, myTrim }) {
  const [errorMsg, setErrorMsg] = useState("");

  const onClickNextBtn = (e) => {
    e.preventDefault();
    setErrorMsg("");

    if (myTrim(name) === "" || myTrim(phone) === "") {
      setErrorMsg("입력되지 않은 값이 있습니다.");
      return;
    }

    const phoneRole = /^\d{3}-\d{3,4}-\d{4}$/;
    if (!phoneRole.test(phone)) {
      setErrorMsg("휴대폰 번호가 유효하지 않습니다.");
      return false;
    }
    gotoNextPage();
  };

  const onChangePhone = (e) => {
    const strOriginal = e.target.value.replace(/[^0-9]/g, "");
    setPhone(strOriginal.replace(/(^01.{1})([0-9]+)([0-9]{4})/, "$1-$2-$3"));
  };

  return (
    <InquiryWarp>
      <StepTitle>
        <h3>
          <span className="step">STEP 2</span> 고객님의 성함과 연락처를 입력해주세요.
        </h3>
      </StepTitle>
      <InquiryBody>
        <Form>
          <input type="text" placeholder="성함" value={name} onChange={onChangeName} />
          <input type="text" placeholder="휴대폰 번호" value={phone} onChange={onChangePhone} maxLength={13} />

          {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}

          <ModalBtn onClick={(e) => onClickNextBtn(e)} disabled={false} id="inquiry2">
            다음
          </ModalBtn>
        </Form>
      </InquiryBody>
    </InquiryWarp>
  );
}

export default InquiryForm2;
