import gql from "graphql-tag";

export const GET_PRODUCT = gql`
  query getProduct($productId: ID) {
    product(productId: $productId) {
      _id
      name
      price
    }
  }
`;

export const GET_APT_CODE = gql`
  query getAptCode($bjdCode: String!, $limit: Int) {
    getAptCode(bjdCode: $bjdCode, limit: $limit) {
      kaptCode
      kaptName
    }
  }
`;

export const GET_KAPT_INFO = gql`
  query getKaptInfo($kaptCode: String!) {
    getKaptInfo(kaptCode: $kaptCode) {
      kaptName
      kaptUsedate
    }
  }
`;

export const VISIT_REQUEST_BY_VISIT_ID = gql`
  query visitRequestByVisitId($visitId: String!) {
    visitRequestByVisitId(visitId: $visitId) {
      _id
      createdAt
      eventTypeId
      eventId
      inviteeId
      type
      slug
      needHeadOffice
      status
      name
      email
      phone
      inviteeStartTime
      inviteeCanceledAt
      visitId
    }
  }
`;

export const GET_ORDER_FROM_BILLLING = gql`
  query getOrderFromBillling($billingId: ID!) {
    getOrderFromBillling(billingId: $billingId) {
      _id
    }
  }
`;

export const GET_ORDERS_WITH_CONTACT_INFO = gql`
  query getOrdersWithContactInfo($email: String!, $phone: String!) {
    getOrdersWithContactInfo(email: $email, phone: $phone) {
      _id
      customerUid
      amount
      status
      billings {
        name
        amount
        paidAt
        status
        type
      }
      visitRequest {
        name
        email
        phone
        inviteeStartTime
        status
        visitId
      }
      # ///
    }
  }
`;

// export const INIT_FILTER_PAGE = gql`
//   query initFilterPage($organizationId: ID) {
//     organization(organizationId: $organizationId) {
//       _id
//       locations {
//         _id
//         kaptInfo
//         storeUrl
//         filterChangeProduct {
//           _id
//           name
//           price
//         }
//         filterProduct {
//           _id
//           name
//           price
//         }
//       }
//     }
//   }
// `;

export const INIT_FILTER_PAGE = gql`
  query initFilterPage($organizationId: ID) {
    organization(organizationId: $organizationId) {
      _id
      locations {
        _id
        kaptInfo
        storeUrl
        filterPrice
        filterChangePrice
      }
    }
  }
`;

export const GET_VISIT_REQUEST = gql`
  query getVisitRequest($visitRequestId: ID) {
    visitRequest(visitRequestId: $visitRequestId) {
      _id
      createdAt
      type
      status
      name
      email
      phone
      inviteeStartTime
      inviteeCanceledAt
      floor {
        _id
        name
        location {
          _id
          kaptInfo
        }
      }
    }
  }
`;

export const GET_SCHEDULE_PROPOSAL = gql`
  query getScheduleProposal($locationId: ID, $floorId: ID) {
    getScheduleProposal(locationId: $locationId, floorId: $floorId) {
      inviteeStartTime
      inviteeEndTime
    }
  }
`;

export const GET_SCHEDULE_LIST = gql`
  query getScheduleList($locationId: ID) {
    getScheduleList(locationId: $locationId) {
      inviteeStartTime
      inviteeEndTime
    }
  }
`;

export const INIT_MAIN_PAGE = gql`
  query initMainPage {
    getLocationsIncludedInOrganization {
      location {
        _id
        kaptInfo
      }
      organizationId
    }
  }
`;
