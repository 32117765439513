import React from "react";

const Theme = React.lazy(() => import("../styles/apartment/theme"));

function apartmentLayout({ children }) {
  return (
    <React.Suspense fallback={<></>}>
      <Theme />
      {children}
    </React.Suspense>
  );
}

export default apartmentLayout;
