import React, { useState } from "react";
import { InquiryWarp, InquiryBody, Form, ErrorMsg, ModalBtn, PostcodeWrap, Postcode, PostCodeCloseButton, StepTitle } from "./style";
import SelectSearch from "react-select-search";
import Axios from "axios";
import styled from "styled-components";
import DaumPostcode from "react-daum-postcode";
import { GET_APT_CODE, GET_KAPT_INFO } from "../../graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";

function InquiryForm4({
  address1,
  setAddress1,
  apartment,
  setApartment,
  address2,
  onChangeAddress2,
  apts,
  setApts,
  aptValue,
  setAptValue,
  kaptCode,
  setKaptCode,
  gotoNextPage,
  myTrim,
}) {
  const [isAddressShow, setIsAddressShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // 다음 페이지 이동
  const onClickNextBtn = (e) => {
    e.preventDefault();
    setErrorMsg("");

    if (myTrim(address1) === "" || myTrim(address2) === "" || myTrim(kaptCode) === "" || myTrim(apartment) === "") {
      setErrorMsg("입력되지 않은 값이 있습니다.");
      return;
    }

    gotoNextPage();
  };

  // 아파트 목록 조회
  const [getAptCode] = useLazyQuery(GET_APT_CODE, {
    onCompleted(data) {
      setIsAddressShow(false);
      setIsLoading(false);
      if (!data.getAptCode) {
        setErrorMsg("선택가능한 아파트가 없습니다.");
        return;
      }
      let aptsData = [];
      Array.isArray(data.getAptCode) ? (aptsData = data.getAptCode) : aptsData.push(data.getAptCode); // 데이터가 하나의 경우 객체로 전달됨

      setApts(
        aptsData.map((cur) => ({
          value: `${cur.kaptCode}__${cur.kaptName}`,
          name: cur.kaptName,
        }))
      );
      setIsAddressShow(false);
      setIsLoading(false);
    },
    onError(error) {
      setIsAddressShow(false);
      setIsLoading(false);
      setErrorMsg("아파트 조회를 실패했습니다.");
    },
  });

  const handleAddress = async (data) => {
    setErrorMsg("");
    setAptValue("");
    setApts([]);
    setApartment("");
    setKaptCode("");

    if (!(data.sido === "서울" || data.sido === "경기")) {
      setErrorMsg("죄송합니다. 서울·경기권 안에서만 신청 가능합니다.");
      setIsAddressShow(false);
      return false;
    }

    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    try {
      setIsLoading(true);

      const kakaoResp = (
        await Axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${fullAddress}`, {
          headers: {
            authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_KEY}`,
          },
        })
      ).data;

      setAddress1(fullAddress);
      if (!(kakaoResp.documents && kakaoResp.documents[0])) return;

      const bCodeData = kakaoResp.documents[0].address.b_code;

      await getAptCode({ variables: { bjdCode: bCodeData, limit: 100 } });
    } catch (error) {
      console.error(error);
      setIsAddressShow(false);
      setIsLoading(false);
    }
  };

  // 특정 아파트 선택
  const [getKaptInfo] = useLazyQuery(GET_KAPT_INFO, {
    onCompleted(data) {
      const year = Number(String(data.getKaptInfo.kaptUsedate).substring(0, 4));
      if (year < 2009) {
        const aptName = data.getKaptInfo.kaptName.length > 8 ? `${data.getKaptInfo.kaptName.substring(0, 7)}...` : data.getKaptInfo.kaptName;
        setErrorMsg(
          `선택하신 아파트(${aptName})는 2009년 이전에 준공되어, 퍼핀플레이스 서비스 설치가 불가합니다.\n환기장치 공사를 진행하셨거나, 공사를 병행하여 서비스 이용을 원하시는 경우, 신청 부탁드립니다.`
        );
      }
    },
    onError(error) {
      setErrorMsg("아파트 조회를 실패했습니다.");
    },
  });

  const selectHandler = async (value) => {
    setErrorMsg("");

    setAptValue(value);
    const aptData = value.split("__");
    setKaptCode(aptData[0]);
    setApartment(aptData[1]);

    await getKaptInfo({ variables: { kaptCode: aptData[0] } });
  };

  return (
    <>
      {isLoading && (
        <Loading>
          <img src="/image/loading.gif" alt="loading" />
        </Loading>
      )}
      <InquiryWarp>
        <StepTitle>
          <h3>
            <span className="step">STEP 4</span> 거주하시는 곳의 주소를 입력해주세요.
          </h3>
        </StepTitle>
        <InquiryBody>
          <Form>
            <input
              type="text"
              placeholder="주소"
              id="inquiry_address_1"
              value={address1}
              onChange={() => {
                return false;
              }}
              onFocus={() => setIsAddressShow(true)}
            />

            <SelectSearch
              options={apts}
              value={aptValue}
              id="inquiry_address_2"
              // search
              disabled={apts.length === 0}
              onChange={selectHandler}
              placeholder="아파트 선택"
            />

            <input type="text" placeholder="상세 주소" value={address2} onChange={onChangeAddress2} />
          </Form>

          <ErrorMsg>
            {errorMsg &&
              errorMsg.split("\n").map((line, i) => {
                return (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                );
              })}
          </ErrorMsg>

          <ModalBtn onClick={(e) => onClickNextBtn(e)} disabled={false} id="inquiry4">
            다음
          </ModalBtn>
        </InquiryBody>
      </InquiryWarp>

      {isAddressShow && (
        <PostcodeWrap>
          <Postcode>
            <PostCodeCloseButton onClick={() => setIsAddressShow(false)}>닫기</PostCodeCloseButton>
            <DaumPostcode onComplete={handleAddress} />
          </Postcode>
        </PostcodeWrap>
      )}
    </>
  );
}

export default InquiryForm4;

const Loading = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;

  img {
    opacity: 0.6;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
