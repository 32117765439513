import React from "react";
import { InquiryWarp, InquiryBody, ModalBtn, StepTitle, Desc } from "./style";
import styled from "styled-components";
import { PopupText, CalendlyEventListener } from "react-calendly";

const StyledModalBtn = styled(ModalBtn)`
  a {
    color: #fff !important;
    text-decoration: none;
  }
`;

function InquiryFormLast({ setIsOpen, orderNum, name, phone, email, isScheduled, setIsScheduled }) {
  const getPhoneStr = (phone) => {
    return phone.replace("010", "8210");
  };

  return (
    <InquiryWarp>
      <StepTitle>
        <h3>신청해 주셔서 감사합니다.</h3>
      </StepTitle>
      <InquiryBody>
        <Desc>
          퍼핀플레이스 체험 신청이 완료되었습니다.
          <br />
          (주문번호 : {orderNum})<br />
          <br />
          {!isScheduled ? (
            <>
              방문 예약신청을 해주세요.
              <br />
              <CalendlyEventListener onEventScheduled={() => setIsScheduled(true)}>
                <StyledModalBtn>
                  <PopupText
                    url="https://calendly.com/puffinplace/air_consulting"
                    text="방문 예약신청"
                    prefill={{
                      name: name,
                      email: email,
                      customAnswers: {
                        a1: getPhoneStr(phone),
                        a2: orderNum,
                      },
                    }}
                  />
                </StyledModalBtn>
              </CalendlyEventListener>
            </>
          ) : (
            <>방문 예약신청이 완료되었습니다.</>
          )}
        </Desc>
        {isScheduled && <ModalBtn onClick={() => setIsOpen(false)}>닫기</ModalBtn>}
      </InquiryBody>
    </InquiryWarp>
  );
}

export default InquiryFormLast;
