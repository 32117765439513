import React from "react";
import styled from "styled-components";
import { Segment, Container } from "../styles/common";
import theme from "../styles/theme";

const P = styled.p`
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
`;

function Footer() {
  return (
    <Segment bgColor={theme.colors.darkestBlue} style={{ paddingTop: 30, paddingBottom: 30 }}>
      <Container>
        <P className="desktop">
          법인명 : 퍼핀플래닛 주식회사&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;대표자 : 선강민&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;사업자 등록번호 : 237-87-01276
          <br />
          사무실 : 서울특별시 강남구 선릉로 555, 505호&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;연구소 : 대전광역시 유성구 대학로 291, 8층(KAIST 나노종합기술원)
          <br />
          이메일 : hello@puffinplace.com&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;대표번호 : 02-508-5225
          <br />
          Copyright© PUFFIN PLACE. All rights reserved.
        </P>
        <P className="phone">
          법인명 : 퍼핀플래닛 주식회사&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;대표자 : 선강민&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;사업자 등록번호 : 237-87-01276
          <br />
          사무실 : 서울특별시 강남구 선릉로 555, 505호
          <br />
          연구소 : 대전광역시 유성구 대학로 291, 8층(KAIST 나노종합기술원)
          <br />
          이메일 : hello@puffinplace.com&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;대표번호 : 02-508-5225
          <br />
          Copyright© PUFFIN PLACE. All rights reserved.
        </P>
      </Container>
    </Segment>
  );
}

export default Footer;
