import React, { useState, useEffect } from "react";
import { Grid, GridItem } from "styled-grid-responsive";
import styled from "styled-components";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { darken } from "polished";
import moment from "moment";
import "moment/locale/ko";
import media from "../../styles/media";

function FilterStep2({ submitInviteeData, inviteeStartTime, setInviteeStartTime, scheduleProposal, scheduleList, isModify = false }) {
  const [pageNum, setPageNum] = useState(1);
  const [currentDate, setCurrentDate] = useState(); // 선택된 날짜
  const [currentDateTimes, setCurrentDateTimes] = useState([]); // 선택된 날짜의 시간 목록
  const [inviteeEndTime, setInviteeEndTime] = useState();

  const [availableDates, setAvailableDates] = useState([]);
  const changeDate = (date) => {
    setCurrentDate(date);
  };

  useEffect(() => {
    if (scheduleProposal && !scheduleProposal.length) setPageNum(2);
  }, [scheduleProposal]);

  useEffect(() => {
    if (!scheduleList) return;
    const tempDates = [];
    scheduleList.forEach((cur, index) => {
      const date = new Date(cur.inviteeStartTime);
      if (index === 0) {
        setCurrentDate(date);
      }
      const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
      tempDates.push(dateStr);
    });
    if (isModify && inviteeStartTime) {
      setCurrentDate(inviteeStartTime);
    }

    setAvailableDates([...new Set(tempDates)]);
  }, [scheduleList]);

  // 선택된 날짜의 일시 설정
  useEffect(() => {
    if (!currentDate) return;
    const tempDates = [];
    scheduleList.forEach((cur, index) => {
      const date = new Date(cur.inviteeStartTime);
      if (date.getFullYear() === currentDate.getFullYear() && date.getMonth() === currentDate.getMonth() && date.getDate() === currentDate.getDate()) {
        tempDates.push(date);
      }
      setCurrentDateTimes(tempDates);
    });
  }, [currentDate]);

  const onClickNextButton = () => {
    setPageNum(2);
  };

  const checkTileDisabled = (date) => {
    const dateStr = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    return !availableDates.includes(dateStr);
  };

  const onClickDateTime = (startDateTime, endDateTime = "") => {
    setInviteeStartTime(startDateTime);
    if (endDateTime) {
      setInviteeEndTime(endDateTime);
    } else {
      setInviteeEndTime(scheduleList.find((cur) => moment(cur.inviteeStartTime).isSame(startDateTime)).inviteeEndTime);
    }
  };

  const onClickSubmit = () => {
    submitInviteeData({ inviteeStartTime: inviteeStartTime, inviteeEndTime: inviteeEndTime });
  };

  return (
    <Segment>
      {pageNum === 2 && !!scheduleProposal.length && (
        <BackBtn onClick={() => setPageNum(1)}>
          <img src="/image/modal/back_btn.png" alt="back" />
        </BackBtn>
      )}
      {isModify ? <H3>방문일 변경</H3> : <H3>방문일 선택</H3>}

      {/* step1  */}
      {pageNum === 1 && !!scheduleProposal.length && (
        <div>
          {scheduleProposal.map((cur, index) => (
            <Item
              key={index}
              onClick={() => onClickDateTime(cur.inviteeStartTime, cur.inviteeEndTime)}
              isChecked={moment(cur.inviteeStartTime).isSame(inviteeStartTime)}
            >
              {moment(cur.inviteeStartTime).locale("ko").format("M월 D일 dddd a h시 m분")}
            </Item>
          ))}
          <NextAnchor onClick={onClickNextButton}>다른 방문일 선택 &gt;</NextAnchor>
        </div>
      )}

      {/* step2 */}
      {pageNum === 2 && (
        <Grid>
          <GridItem col={3 / 5} media={{ phone: 1 }}>
            <StyledCalendar
              onChange={(date) => changeDate(date)}
              value={currentDate}
              tileDisabled={({ date }) => {
                return checkTileDisabled(date);
              }}
            />
          </GridItem>
          <GridItem col={2 / 5} media={{ phone: 1 }}>
            <P>{moment(currentDate).locale("ko").format("YYYY년 M월 D일 dddd")}</P>
            <ItemWrap>
              {currentDateTimes.map((cur, index) => (
                <Item key={index} onClick={() => onClickDateTime(cur)} isChecked={moment(cur).isSame(inviteeStartTime)}>
                  {moment(cur).locale("ko").format("a h시 m분")}
                </Item>
              ))}
            </ItemWrap>
          </GridItem>
        </Grid>
      )}

      <Button disabled={!inviteeStartTime} onClick={onClickSubmit}>
        확인
      </Button>
    </Segment>
  );
}

export default FilterStep2;

const BackBtn = styled.div`
  position: absolute;
  left: 2rem;
  top: 2.5rem;
  cursor: pointer;
`;

const Segment = styled.div`
  position: relative;
  margin: 0 auto;
  border-radius: 20px;
  padding: 20px;
  width: 600px;
  ${media.phone`
    width: 100%;       
  `}
  background-color: #fff;
`;

const H3 = styled.h3`
  color: #404040;
  font-size: 2.7rem;
  font-weight: 500;
  line-height: 1.2em;
  text-align: center;
  margin: 0 0 40px 0;
  ${media.phone`
  margin-bottom: 10px;          
  `}
`;

const P = styled.p`
  ${media.phone`
    margin-top: 20px;       
  `}
  color: #404040;
  font-size: 2rem;
  font-weight: 300;
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  text-align: center;
  display: block;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 700;
  /* width: 200px; */
  background: ${(props) => (props.disabled ? "#2a5981" : "#1caae5")};
  color: ${(props) => (props.disabled ? "#c4c4c4" : "#FFF")};
  line-height: 3rem;
  /* border-radius: 4rem; */
  /* padding: 1.3rem 4rem; */
  border-radius: 30px;
  padding: 10px 60px;
  transition: all 0.5s;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#2a5981" : darken(0.1, "#1caae5"))};
  }
  ${media.phone`
    font-size: 2.4rem;
  `}
`;

const Item = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 16px 14px;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
  color: ${(props) => (props.isChecked ? "#FFF" : "#444")};
  background: ${(props) => (props.isChecked ? "#1caae5" : "#FFF")};
  &:hover {
    background-color: #1caae5;
    color: #fff;
  }
`;

const ItemWrap = styled.div`
  max-height: 284px;
  ${media.phone`
    margin-top: 20px;
    margin-bottom: 20px;
  `}
  overflow: scroll;
  padding: 0 10px 0 0;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
  }
`;

const NextAnchor = styled.p`
  text-align: right;
  color: #c4c4c4;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
`;

const StyledCalendar = styled(Calendar)`
  border: 0px solid transparent !important;

  .react-calendar__tile--active {
    background-color: #1caae5;
  }
  .react-calendar__tile--active:enabled:hover {
    background-color: #1caae5;
  }
`;
