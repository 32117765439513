import React, { useState, useEffect } from "react";
import Header from "../../components/apartment/header";
import { GET_APT_CODE, GET_KAPT_INFO } from "../../graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";
import SelectSearch from "react-select-search";
import DaumPostcode from "react-daum-postcode";
import Axios from "axios";
import ApartmentLayout from "../../layout/apartmentLayout";

import { PopupText, CalendlyEventListener } from "react-calendly";

function New() {
  const [address1, setAddress1] = useState("");
  const [aprtment, setApartment] = useState("");
  const [address2, setAddress2] = useState("");

  const [apts, setApts] = useState([]);
  const [aptValue, setAptValue] = useState("");

  const [isAddressShow, setIsAddressShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isSubmitAble, setIsSubmitAble] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const myTrim = (x) => {
    return x.replace(/^\s+|\s+$/gm, "");
  };

  useEffect(() => {
    if (myTrim(address1) && myTrim(aprtment) && myTrim(address2)) {
      setIsSubmitAble(true);
    } else {
      setIsSubmitAble(false);
    }
  }, [address1, aprtment, address2]);

  // 아파트 목록 조회
  const [getAptCode] = useLazyQuery(GET_APT_CODE, {
    onCompleted(data) {
      setIsAddressShow(false);
      setIsLoading(false);
      if (!data.getAptCode) {
        setErrorMsg("선택가능한 아파트가 없습니다.");
        return;
      }
      let aptsData = [];
      Array.isArray(data.getAptCode) ? (aptsData = data.getAptCode) : aptsData.push(data.getAptCode); // 데이터가 하나의 경우 객체로 전달됨

      setApts(
        aptsData.map((cur) => ({
          value: `${cur.kaptCode}__${cur.kaptName}`,
          name: cur.kaptName,
        }))
      );
      aptsData.length && setApartment(aptsData[0].kaptName);
      setIsAddressShow(false);
      setIsLoading(false);
    },
    onError(error) {
      setIsAddressShow(false);
      setIsLoading(false);
      setErrorMsg("아파트 조회를 실패했습니다.");
    },
  });

  const handleAddress = async (data) => {
    setErrorMsg("");
    setAptValue("");
    setApts([]);
    setApartment("");

    if (!(data.sido === "서울" || data.sido === "경기")) {
      setErrorMsg("죄송합니다. 서울·경기권 안에서만 신청 가능합니다.");
      setIsAddressShow(false);
      return false;
    }

    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    try {
      setIsLoading(true);

      const kakaoResp = (
        await Axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${fullAddress}`, {
          headers: {
            authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_KEY}`,
          },
        })
      ).data;

      setAddress1(fullAddress);
      if (!(kakaoResp.documents && kakaoResp.documents[0])) return;

      const bCodeData = kakaoResp.documents[0].address.b_code;

      await getAptCode({ variables: { bjdCode: bCodeData, limit: 100 } });
    } catch (error) {
      console.error(error);
      setIsAddressShow(false);
      setIsLoading(false);
    }
  };

  // 특정 아파트 선택
  const [getKaptInfo] = useLazyQuery(GET_KAPT_INFO, {
    onCompleted(data) {
      const year = Number(String(data.getKaptInfo.kaptUsedate).substring(0, 4));
      if (year < 2009) {
        const aptName = data.getKaptInfo.kaptName.length > 8 ? `${data.getKaptInfo.kaptName.substring(0, 7)}...` : data.getKaptInfo.kaptName;
        setErrorMsg(
          `선택하신 아파트(${aptName})는 2009년 이전에 준공되어, 퍼핀플레이스 서비스 설치가 불가합니다.\n환기장치 공사를 진행하셨거나, 공사를 병행하여 서비스 이용을 원하시는 경우, 신청 부탁드립니다.`
        );
      }
    },
    onError(error) {
      console.error("error", error);
      setErrorMsg("아파트 조회를 실패했습니다.");
    },
  });

  // 아파트 선택
  const selectHandler = async (value) => {
    setErrorMsg("");

    setAptValue(value);
    const aptData = value.split("__");
    setApartment(aptData[1]);

    await getKaptInfo({ variables: { kaptCode: aptData[0] } });
  };

  if (isSuccess) {
    return (
      <ApartmentLayout>
        <Header />
        <div className="page-body">
          <h2 className="page-title">첫 단지 고객 신청</h2>
          <h3 className="mt-2">퍼핀팀이 직접 방문하여 최적의 필터를 주문제작해드립니다.</h3>

          <p className="text-xl text-center mt-20">신청 되었습니다. 남겨주신 연락처로 연락 드리겠습니다.</p>
        </div>
      </ApartmentLayout>
    );
  }

  if (!isSuccess) {
    return (
      <ApartmentLayout>
        <div className={isLoading ? "overflow-hidden h-screen" : ""}>
          <div className={(!isLoading && "hidden") + " loading-wrapper"}>
            <img className="w-6" src="/image/apartment/common/loading.gif" alt="loading" />
          </div>
          <Header />
          <div className="page-body">
            <h2 className="page-title">첫 단지 고객 신청</h2>
            <h3 className="mt-2">퍼핀팀이 직접 방문하여 최적의 필터를 주문제작해드립니다.</h3>

            <div className="form">
              <div className="form-group">
                <label className="form-label" htmlFor="inquiry_address_1">
                  주소
                </label>
                {/* <input className="form-input" type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} /> */}
                <input
                  className="form-input"
                  type="text"
                  // placeholder="주소"
                  id="inquiry_address_1"
                  value={address1}
                  onChange={() => {
                    return false;
                  }}
                  onFocus={() => setIsAddressShow(true)}
                />
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="inquiry_address_2">
                  아파트 선택
                </label>
                <div className="form-input new-select-search">
                  <SelectSearch
                    options={apts}
                    value={aptValue}
                    id="inquiry_address_2"
                    // search
                    disabled={apts.length === 0}
                    onChange={selectHandler}
                    // placeholder="아파트 선택"
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="inquiry_address_1">
                  상세 주소
                </label>
                <input className="form-input" type="text" placeholder="상세 주소" value={address2} onChange={(e) => setAddress2(e.target.value)} />
              </div>

              <p className="text-main-blue my-10 text-lg text-center">{errorMsg ? errorMsg : <>&nbsp;</>}</p>
            </div>
            {isAddressShow && (
              <>
                <div className="absolute top-0 left-0 w-full h-full bg-black z-20 opacity-40 flex justify-center items-center" />
                <div className="w-1/2 border shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white z-50">
                  <div className="relative pt-7">
                    <div className="absolute right-2 top-2 cursor-pointer" onClick={() => setIsAddressShow(false)}>
                      닫기
                    </div>
                    <DaumPostcode onComplete={handleAddress} />
                  </div>
                </div>
              </>
            )}
            <div className="text-center">
              {isSubmitAble ? (
                <CalendlyEventListener onEventScheduled={() => setIsSuccess(true)}>
                  <div className="calendly-wrap">
                    <PopupText
                      url="https://calendly.com/puffinplace/first"
                      text="방문 예약신청"
                      prefill={{
                        customAnswers: {
                          a2: address1 + " " + aprtment + " " + address2,
                        },
                      }}
                    />
                  </div>
                </CalendlyEventListener>
              ) : (
                <div className="bg-main-blue-dark text-gray-300 px-14 py-2 rounded inline-block">방문 예약신청</div>
              )}
            </div>
          </div>
        </div>
      </ApartmentLayout>
    );
  }
}

export default New;
